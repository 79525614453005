import React, {useState, createContext} from 'react';
import {Animated, Platform, Dimensions} from 'react-native';
import {getStatusBarHeight} from 'react-native-status-bar-height';

const screenHeight = Dimensions.get('window').height;
const deviceHeight = Dimensions.get('screen').height;
const bottomNavBarHeight = 0;
const displayHeight = screenHeight;

export default createContext({
  menuXPos: new Animated.Value(-350),
  mainXPos: new Animated.Value(0),
  overScreenXPos: new Animated.Value(-10),
  overScreenYPos: new Animated.Value(-displayHeight),
  overScreenOpacity: new Animated.Value(0),
  menuItemXPos: new Animated.Value(0),
  secondaryMenuItemXPos: new Animated.Value(350),
  playerYPos: new Animated.Value(0),
});
