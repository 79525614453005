import React, {Component} from 'react';
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  Text,
  View,
  Image,
  ImageBackground,
  Platform,
  Animated,
  Easing,
  useColorScheme,
  Linking,
  Button,
  TouchableOpacity,
  Pressable,
  ActivityIndicator,
} from 'react-native';
import * as FileSystem from 'expo-file-system';
import * as SQLite from 'expo-sqlite'

import {openDatabase} from '../database/Database';

import Context from '../contexts/context';

import * as Icon from "react-native-heroicons/outline";

import MenuItem from './MenuItem';

import leather from '../../assets/images/leather.png';
import menuCornerUL from '../../assets/images/menu_corner_upper_left.png';
import menuCornerLL from '../../assets/images/menu_corner_lower_left.png';
import menuCornerUR from '../../assets/images/menu_corner_upper_right.png';
import menuCornerLR from '../../assets/images/menu_corner_lower_right.png';

const isNative = Platform.OS !== 'web';

const initialData = [
  {'icon': null, 'name': ''},
  {'icon': <Icon.BookOpenIcon size={20} color='#ffc029' />, 'name': 'Sourates', 'isParent': true, 'action': 'sourate_list'},
  {'icon': <Icon.PuzzlePieceIcon size={20} color='#ffc029' />, 'name': 'Ahzab ', 'isParent': true, 'action': 'ahzab_list'},
  {'icon': <Icon.MicrophoneIcon size={20} color='#ffc029' />, 'name': 'Récitateurs', 'isParent': true, 'action': 'narrator_list'},
  {'icon': <Icon.BookmarkIcon size={20} color='#ffc029' />, 'name': 'Marque-Pages', 'isParent': true, 'action': 'bookmark_list'},
  {'icon': <Icon.GlobeEuropeAfricaIcon size={20} color='#ffc029' />, 'name': 'Texte en arabe', 'action': 'text_ar'},
  {'icon': <Icon.GlobeEuropeAfricaIcon size={20} color='#ffc029' />, 'name': 'Interprétation française', 'action': 'text_fr'},
  {'icon': <Icon.GlobeEuropeAfricaIcon size={20} color='#ffc029' />, 'name': 'Translittération française', 'action': 'trans_fr'},
  {'icon': <Icon.QuestionMarkCircleIcon size={20} color='#ffc029' />, 'name': 'Aide', 'action': 'help', 'entity': 'help'},
];

export default class Menu extends Component {
  state = {
    data: initialData,
    secondaryData: [],
    level: 1,
    loading: true,
    textArHL: true,
    textFrHL: false,
    transFrHL: false,
    narratorId: false,
    bookmarks: [],
  }
  static contextType = Context;
  sourates = [];
  ahzab = [];
  narrators = [];
  bookmarks = [];
  scrollViewRef = null;
  loadingContentData = null;
  db = null;

  componentDidMount = async () => {
    this.loadingContentData = <View style={this.props.styles.menuLoaderContainer}><ActivityIndicator style={this.props.styles.menuLoader} color={this.props.menuLoaderColor}></ActivityIndicator></View>;
    this.scrollViewRef = React.createRef();
    var T = this;
    var interval = setInterval(async function () {
      T.db = await openDatabase(false);
      if (T.db === null) {
        return;
      }
      clearInterval(interval);
      T.load();
    }, 2000);
  }

  load = async () => {
    // load ahzab list
    var items = [];
    items.push({'name': 'Ahzab', 'action': 'label'});
    items.push({'name': 'Retour', 'action': 'back'});
    for (var i = 1; i < 61; i++) {
      items.push({'icon': null, 'name': 'Hizb '+i, 'action': 'close', 'entity': 'hizb', 'data': {'number': i}, 'url': '/hizb/'+i});
    }
    this.ahzab = items;

    // load sourates list
    var items2 = [];
    items2.push({'name': 'Sourates', 'action': 'label'});
    items2.push({'name': 'Retour', 'action': 'back'});
    // load narrators list
    var items3 = [];
    items3.push({'name': 'Récitateurs', 'action': 'label'});
    items3.push({'name': 'Retour', 'action': 'back'});
    if (isNative) {
      var data = [];
      this.db.readTransaction(tx => {
        tx.executeSql('SELECT * FROM sourate \
          INNER JOIN translation ON translation.text_entry_id = sourate.name \
          AND translation.language_id IN ("1", "2")', null,
          (txObj, {rows: {_array}}) => {
            _array.forEach((sourate) => {
              if (data[sourate.number] === undefined) {
                data[sourate.number] = sourate;
              }
              if (data[sourate.number]['sourateNames'] === undefined) {
                data[sourate.number]['sourateNames'] = {};
              }
              data[sourate.number]['sourateNames'][sourate['language_id']] = sourate.content;
            })
            data.forEach((sourate) => {
              items2.push({
                'icon': null,
                'name': sourate['sourateNames']['2'],
                'action': 'close',
                'entity': 'verset',
                'entity_id': sourate['number'],
                'parent': 'sourate_id',
                'parent_id': sourate['number'],
                'data': sourate,
              });
            });
            this.sourates = items2;
            if (this.narrators.length) {
              // all is loaded
              this.setState({
                loading: false,
                textFrHL: this.props.textFr,
                transFrHL: this.props.transFr,
                narratorId: this.props.narratorId,
              }, function() {this.dataReady()});
            }
          },
          (txObj, error) => {console.log('Error ', error)}
        )
      })
      var data2 = [];
      this.db.readTransaction(tx => {
        tx.executeSql('SELECT * FROM narrator \
          INNER JOIN translation ON translation.text_entry_id = narrator.name \
          AND translation.language_id IN ("1", "2")', null,
          (txObj, {rows: {_array}}) => {
            _array.forEach((narrator) => {
              data2[narrator.id] = narrator;
              if (data2[narrator.id]['narratorNames'] === undefined) {
                data2[narrator.id]['narratorNames'] = {};
              }
              data2[narrator.id]['narratorNames'][narrator['language_id']] = narrator.content;
            })
            data2.forEach((narrator) => {
              // icon can't be null, st size to 0
              var icon = <Icon.BarsArrowDownIcon  style={this.props.styles.narratorFollowText} size={0} color='#ffc029'/>;
              if (narrator['follow_text'] === 1) {
                icon = <Icon.BarsArrowDownIcon  style={this.props.styles.narratorFollowText} size={20} color='#ffc029'/>;
              }
              items3.push({
                'icon': icon,
                'lang': narrator['lang'],
                'name': narrator['narratorNames']['2'],
                'data': narrator,
                'action': 'narrator',
                'entity': 'narrator',
                'entity_id': narrator['id'],
                'downloadable': false, // true,
              });
            });
            this.narrators = items3;
            if (this.sourates.length) {
              // all is loaded
              this.setState({
                loading: false,
                textFrHL: this.props.textFr,
                transFrHL: this.props.transFr,
                narratorId: this.props.narratorId,
              }, function() {this.dataReady()});
            }
          },
          (txObj, error) => {console.log('Error ', error)}
        )
      })
    } else {
      await fetch('https://qaraa.fr/api/sourates')
        .then((response) => response.json())
        .then((json) => {
          json.forEach((sourate) => {
            sourate.url = '/sourate/'+sourate['number'];
            items2.push({
              'icon': null,
              'name': sourate['sourateNames']['2'],
              'action': 'close',
              'entity': 'verset',
              'entity_id': sourate['number'],
              'parent': 'sourate_id',
              'parent_id': sourate['number'],
              'data': sourate,
            });
          });
          this.sourates = items2;
        })
        .catch((error) => {
          console.error(error);
        })
      ;
      await fetch('https://qaraa.fr/api/narrators')
        .then((response) => response.json())
        .then((json) => {
          json.forEach((narrator) => {
            // icon can't be null, st size to 0
            var icon = <Icon.BarsArrowDownIcon  style={this.props.styles.narratorFollowText} size={0} color='#ffc029'/>;
            if (narrator['followText'] === 1) {
              icon = <Icon.BarsArrowDownIcon  style={this.props.styles.narratorFollowText} size={20} color='#ffc029'/>;
            }
            items3.push({
              'icon': icon,
              'lang': narrator['lang'],
              'name': narrator['narratorNames']['2'],
              'data': narrator,
              'action': 'narrator',
              'entity': 'narrator',
              'entity_id': narrator['number'],
              'downloadable': false,
            });
          });
          this.narrators = items3;
        })
        .catch((error) => {
          console.error(error);
        })
      ;
  
      // all is loaded
      this.setState({
        loading: false,
        textFrHL: this.props.textFr,
        transFrHL: this.props.transFr,
        narratorId: this.props.narratorId,
      }, function() {this.dataReady()});
    }
  }

  dataReady = async () => {
    var T = this;
    if (isNative) {
      var mediaDir = FileSystem.documentDirectory + 'Qaraa/media/';
      var summaryFile = mediaDir + 'summary.json';
      var summary = JSON.parse(await FileSystem.readAsStringAsync(summaryFile));
      // analyze summary.json vs local files
      // don't analyze 2 first entries
      for (var i = 2; i < this.narrators.length; i++) {
        var narratorId = this.narrators[i]['entity_id'];
        this.narrators[i]['download_offset'] = await this.seekSourateDownloadsOffset(narratorId, false);
      }
    }

    this.props.dataLoaded(this.sourates, this.narrators, this.updateBookmarks);
  }
  
  updateBookmarks = async (bookmarks) => {
    // set bookmarks list, by transforming data
    var items = [];
    items.push({'name': 'Marque-Pages', 'action': 'label'});
    items.push({'name': 'Retour', 'action': 'back'});
    if (bookmarks) {
      var keys = Object.keys(bookmarks);
      for (var i = 0; i < keys.length; i++) {
        var key = keys[i].split('-');
        if (key[0] === 'Hizb') {
          // get hizb from ahzab
          var hizbItem = JSON.parse(JSON.stringify(this.ahzab.find(function(hizb, index) {
            if(hizb['name'] === 'Hizb '+key[1])
              return true;
          })));
          hizbItem['name'] = 's'+key[2]+' v'+key[3]+' ('+key[0]+')';
          hizbItem['goto'] = {'sourate' : parseInt(key[2]), 'verset': parseInt(key[3])};
          hizbItem['entity_id'] = parseInt(key[2]);
          hizbItem['verset'] = parseInt(key[3]);
          hizbItem['bookmark'] = true;
          items.push(hizbItem);
        }
        if (key[0] === 'Sourate') {
          // get sourate from sourates
          var sourateItem = JSON.parse(JSON.stringify(this.sourates.find(function(sourate, index) {
            if(sourate['data'] && sourate['data']['number'] === parseInt(key[1])) {
              return true;
            }
          })));
          sourateItem['name'] = 's'+key[2]+' v'+key[3]+' ('+key[0]+')';
          sourateItem['goto'] = {'sourate' : parseInt(key[2]), 'verset': parseInt(key[3])};
          sourateItem['entity_id'] = parseInt(key[2]);
          sourateItem['verset'] = parseInt(key[3]);
          sourateItem['bookmark'] = true;
          items.push(sourateItem);
        }
      }
    }
    items.sort(this.getSortOrder('entity_id', 'verset')); 
    this.bookmarks = items;
  }

  getSortOrder = (prop, prop2) => {
    return function(a, b) {
      if (prop2 !== undefined) {
        return a[prop] - b[prop] || a[prop2] - b[prop2];
      }
      return b[prop] - a[prop];
    }
  }

  seekSourateDownloadsOffset = async (narratorId, deep) => {
    var mediaDir = FileSystem.documentDirectory + 'Qaraa/media/';
    for (var j = 1; j < 115; j++) {
      var sourate = String(j).padStart(3, '0');
      const mp3FileInfo = await FileSystem.getInfoAsync(mediaDir + narratorId + '_' + sourate + '.mp3');
      if (mp3FileInfo.exists) {
        if (deep === false) {
          if (sourate === '001') {
            // first sourate present ? goto last one
            j = 113;
          }
          if (sourate === '114') {
            // last sourate present ? narrator ok
            return j;
          }
        }
      } else {
        if (deep === false) {
          if (sourate === '001') {
            // first sourate not present ? return 0
            return 0;
          }
          if (sourate === '114') {
            // last sourate not present ? must seek for offset
            return this.seekSourateDownloadsOffset(narratorId, true);
          }
        } else {
          return j;
        }
      }
    }
  }

  onPress = async (props) => {
    var level = 1;
    var secondaryData = [];
    switch (props.action) {
      case 'sourate_list':
        secondaryData = this.sourates;
        level = 2;
        break;
      case 'ahzab_list':
        secondaryData = this.ahzab;
        level = 2;
        break;
      case 'narrator_list':
        // sort just for the display
        var narrators = this.narrators.slice();
        narrators.shift();
        narrators.shift();
        narrators.sort(this.getSortOrder('name')); 
        narrators.unshift({'name': 'Retour', 'action': 'back'});
        narrators.unshift({'name': 'Récitateurs', 'action': 'label'});
        secondaryData = narrators;
        level = 2;
        break;
      case 'bookmark_list':
        secondaryData = this.bookmarks;
        level = 2;
        break;
      case 'text_ar':
        break;
      case 'text_fr':
        this.setState({
          textFrHL: !this.state.textFrHL,
        }, function() {this.props.textHLChanged(this.state.textFrHL, this.state.transFrHL)});
        break;
      case 'trans_fr':
        this.setState({
          transFrHL: !this.state.transFrHL,
        }, function() {this.props.textHLChanged(this.state.textFrHL, this.state.transFrHL)});
        break;
      case 'narrator':
        this.setState({
          narratorId: parseInt(props.entityId),
        }, function() {this.props.narratorHLChanged(this.state.narratorId)});
        return;
      case 'help':
      case 'close':
        this.props.toggleMenu();
        // T.scrollViewRef.current?.scrollTo({x: 0, y: 0, animated: false});
        var propsCopy = JSON.parse(JSON.stringify(props));
        if (propsCopy.entity !== undefined) {
          delete propsCopy.styles;
          this.props.populateMain(propsCopy);
        }
        return;
      case 'back':
        secondaryData = this.state.secondaryData;
        break;
      default:
        return;
    };
    this.setState({
      secondaryData: secondaryData,
      level: level,
    }, function() {this.animate(level)});
  }

  animate = (level) => {
    var menuItemXPosVal = -350;
    var secondaryMenuItemXPosVal = 0;
    if (level === 1) {
      menuItemXPosVal = 0;
      secondaryMenuItemXPosVal = 350;
    }
    if (this.context.menuItemXPos === menuItemXPosVal && this.context.secondaryMenuItemXPos === secondaryMenuItemXPosVal) {
      return;
    }
    Animated.timing(this.context.menuItemXPos, {
      toValue: menuItemXPosVal,
      easing: Easing.out(Easing.ease),
      duration: 200,
      useNativeDriver: true,
    }).start();
    Animated.timing(this.context.secondaryMenuItemXPos, {
      toValue: secondaryMenuItemXPosVal,
      easing: Easing.out(Easing.ease),
      duration: 200,
      useNativeDriver: true,
    }).start();
  }

  UNSAFE_componentWillReceiveProps(props) {
    // update bookmarks ?
    // -2 because of the first 2 entries
    if (this.bookmarks.length - 2 !== Object.keys(props.bookmarks).length) {
      this.updateBookmarks(props.bookmarks);
    }
    var state = {level: 1};
    if (props.textFr) {
      state.textFrHL = props.textFr;
    }
    if (props.transFr) {
      state.transFrHL = props.transFr;
    }
    if (props.narratorId) {
      state.narratorId = props.narratorId;
    }
    this.setState(state);
  }

  render() {
    return (
        this.state.loading ?
          <Animated.View
            style={[this.props.styles.menuContainer, {transform: [{translateX: this.context.menuXPos}]}]}>
            <View style={this.props.styles.menuSlideContainer}>
              <ImageBackground source={leather} style={this.props.styles.leather} />
              <ImageBackground source={menuCornerUL} style={this.props.styles.menuCornerUL} />
              <ImageBackground source={menuCornerLL} style={this.props.styles.menuCornerLL} />
              <ImageBackground source={menuCornerUR} style={this.props.styles.menuCornerUR} />
              <ImageBackground source={menuCornerLR} style={this.props.styles.menuCornerLR} />
            </View>
            {this.loadingContentData}
          </Animated.View>
        :
          <Animated.View
            style={[this.props.styles.menuContainer, {transform: [{translateX: this.context.menuXPos}]}]}>
            <View style={this.props.styles.menuSlideContainer}>
              <ImageBackground source={leather} style={this.props.styles.leather} />
              <ImageBackground source={menuCornerUL} style={this.props.styles.menuCornerUL} />
              <ImageBackground source={menuCornerLL} style={this.props.styles.menuCornerLL} />
              <ImageBackground source={menuCornerUR} style={this.props.styles.menuCornerUR} />
              <ImageBackground source={menuCornerLR} style={this.props.styles.menuCornerLR} />
            </View>
            <Animated.ScrollView
              contentInsetAdjustmentBehavior="automatic"
              style={[this.props.styles.menuScrollView, {transform: [{translateX: this.context.secondaryMenuItemXPos}]}]}
              ref={this.scrollViewRef}>
              {this.state.secondaryData.map((n, index) => (
                <Animated.View key={index}>
                  <MenuItem
                    styles={this.props.styles}
                    fontsLoaded={this.props.fontsLoaded}
                    menuLoaderColor={this.props.menuLoaderColor}
                    icon={n['icon']}
                    lang={n['lang']}
                    merge={n['merge'] === undefined ? false : n['merge']}
                    name={n['name']}
                    number={n['data'] === undefined ? null : n['data']['number']}
                    name1={n['data'] === undefined ? null : n['data']['name_1']}
                    name2={n['data'] === undefined ? null : n['data']['name_2']}
                    isParent={n['isParent']}
                    action={n['action']}
                    entity={n['entity']}
                    entityId={n['entity_id']}
                    parent={n['parent']}
                    parentId={n['parent_id']}
                    bookmark={n['bookmark']}
                    url={(n['data'] !== undefined && n['data']['url'] !== undefined) ? n['data']['url'] : null}
                    goto={n['goto']}
                    narratorHL={n['entity'] === 'narrator' && this.state.narratorId === parseInt(n['entity_id'])}
                    sourateHL={n['bookmark'] === undefined && n['entity'] === 'verset' && this.props.currentSourate === parseInt(n['parent_id'])}
                    hizbHL={n['bookmark'] === undefined && n['data'] !== undefined && n['entity'] === 'hizb' && this.props.currentHizb === parseInt(n['data']['number'])}
                    press={this.onPress}
                    downloadable={n['downloadable']}
                    downloadOffset={n['download_offset']}></MenuItem>
                </Animated.View>
              ))}
            </Animated.ScrollView>
            <Animated.ScrollView
              contentInsetAdjustmentBehavior="automatic"
              style={[this.props.styles.menuScrollView, {transform: [{translateX: this.context.menuItemXPos}]}]}>
              {this.state.data.map((n, index) => (
                <Animated.View key={index}>
                  <MenuItem
                    styles={this.props.styles}
                    fontsLoaded={this.props.fontsLoaded}
                    menuLoaderColor={this.props.menuLoaderColor}
                    icon={n['icon']}
                    lang={n['lang']}
                    merge={n['merge'] === undefined ? false : n['merge']}
                    name={n['name']}
                    number={n['data'] === undefined ? n['data'] : n['data']['number']}
                    name1={n['data'] === undefined ? n['data'] : n['data']['name_1']}
                    name2={n['data'] === undefined ? n['data'] : n['data']['name_2']}
                    isParent={n['isParent']}
                    action={n['action']}
                    entity={n['entity']}
                    entityId={n['entity_id']}
                    parent={n['parent']}
                    parentId={n['parent_id']}
                    textArHL={this.state.textArHL}
                    textFrHL={this.state.textFrHL}
                    transFrHL={this.state.transFrHL}
                    press={this.onPress}
                    downloadable={n['downloadable']}
                    downloadOffset={n['download_offset']}
                    souratesLength={this.sourates.length - 2}
                    ahzabLength={this.ahzab.length - 2}
                    narratorsLength={this.narrators.length - 2}
                    bookmarksLength={this.bookmarks.length - 2}></MenuItem>
                </Animated.View>
              ))}
            </Animated.ScrollView>
        </Animated.View>
    );
  }
}
