import React, {Component} from 'react';
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  Text,
  View,
  Image,
  ImageBackground,
  Platform,
  Animated,
  Easing,
  useColorScheme,
  Linking,
  Button,
  TouchableOpacity,
  Pressable,
  ActivityIndicator,
} from 'react-native';
import * as Icon from "react-native-heroicons/outline";
import { AnimatedCircularProgress } from 'react-native-circular-progress';
import * as FileSystem from 'expo-file-system';

import Context from '../contexts/context';

const isNative = Platform.OS !== 'web';

export default class MenuItem extends Component {
  state = {
    downloadOffset: [],
    downloading: false,
  }
  static contextType = Context;

  constructor(props) {
    super(props);
    this.state = {
      downloadOffset: props.downloadOffset,
      downloading: false,
    };
  }

  onPress = async () => {
    this.props.press(this.props);
  }

  onDownloadPress = async () => {
    var offset = this.state.downloadOffset;
    if (isNative) {
      var mediaDir = FileSystem.documentDirectory + 'Qaraa/media/';
      if (offset == 0) {
        offset = 1;
        this.setState({
          downloadOffset: offset,
        });
      }
      for (var i = offset; i < 115; i++) {
        var sourate = String(i).padStart(3, '0');
        var filename = this.props.entityId + '_' + sourate;
        console.log('downloading ' + filename + '...');
        this.setState({
          downloading: true,
        });
        var result = await FileSystem.downloadAsync('https://qaraa.fr/media/' + filename + '.mp3', mediaDir + filename + '.mp3');
        if (result.status === 200) {
            this.setState({
              downloadOffset: i,
            });
        } else {
          console.error(result.statusText);
          break;
        }
      }
      this.setState({
        downloading: false,
      });
    }
  }

  render() {
    return (
      <View>
        {this.props.name === '' ? 
          <View>
            <Text selectable={false} key={this.props.name} style={[this.props.styles.menuItem, (this.props.fontsLoaded ? this.props.styles.contentFr : null)]}>
              <View style={this.props.styles.menuSVGIconContainer}>
                <View style={this.props.styles.menuSVGIcon}>{this.props.icon}</View>
              </View>
              {this.props.name}
              {this.props.isParent ? <View style={this.props.styles.menuSVGRightArrowIconContainer}><View style={this.props.styles.menuSVGRightArrowIcon}><Icon.ChevronRightIcon size={20} color='#ffc029'/></View></View> : null}
            </Text>
          </View>
          :
          <Pressable
            disabled={(this.props.downloadable && this.state.downloadOffset === 0) ? true : false}
            style={({ hovered, focused, pressed }) => [
              hovered && [this.props.action === 'label' ? null : this.props.styles.menuItemHover],
            ]}
            onPress={() => {
              this.onPress();
            }}>
            {this.props.icon === null ?
              null
              :
              <View style={this.props.styles.menuSVGIconContainer}><View style={this.props.styles.menuSVGIcon}>{this.props.icon}</View></View>
            }
            <Text selectable={false} key={this.props.name} style={
              this.props.action === 'label' ? [this.props.styles.menuItemLabel, this.props.fontsLoaded ? this.props.styles.menuItemLabelFF : null] : (
                this.props.action === 'back' ? this.props.styles.menuItemBack : (
                  (this.props.action === 'narrator' && this.props.narratorHL === false) ? [this.props.styles.menuItem, (this.props.fontsLoaded ? this.props.styles.contentFr : null), ((this.props.downloadable && this.state.downloadOffset === 0) ? this.props.styles.menuTextDisabled : null)] : (
                    this.props.icon === null ? [this.props.styles.menuItem, (this.props.fontsLoaded ? this.props.styles.contentFr : null), this.props.styles.menuItemWithoutIcon, ((this.props.downloadable && this.state.downloadOffset === 0) ? this.props.styles.menuTextDisabled : null), ((this.props.sourateHL || this.props.hizbHL) ? [this.props.styles.menuTextHL, (this.props.fontsLoaded ? this.props.styles.menuTextHLFF : null)] : null)] : (
                      (this.props.textArHL && this.props.action === 'text_ar') ? [this.props.styles.menuItem, (this.props.fontsLoaded ? this.props.styles.contentFr : null), this.props.styles.menuTextHL, (this.props.fontsLoaded ? this.props.styles.menuTextHLFF : null)] : (
                        (this.props.textFrHL && this.props.action === 'text_fr') ? [this.props.styles.menuItem, (this.props.fontsLoaded ? this.props.styles.contentFr : null), this.props.styles.menuTextHL, (this.props.fontsLoaded ? this.props.styles.menuTextHLFF : null)] : (
                          (this.props.transFrHL && this.props.action === 'trans_fr') ? [this.props.styles.menuItem, (this.props.fontsLoaded ? this.props.styles.contentFr : null), this.props.styles.menuTextHL, (this.props.fontsLoaded ? this.props.styles.menuTextHLFF : null)] : (
                            this.props.narratorHL ? [this.props.styles.menuItem, (this.props.fontsLoaded ? this.props.styles.contentFr : null), this.props.styles.menuTextHL, (this.props.fontsLoaded ? this.props.styles.menuTextHLFF : null)] : [this.props.styles.menuItem, (this.props.fontsLoaded ? this.props.styles.contentFr : null)]
                          )
                        )
                      )
                    )
                  )
                )
              )
            }>
              {(this.props.icon === null && this.props.number && this.props.entity === 'verset' && this.props.bookmark === undefined) ? this.props.number + ' - ' : null} {this.props.name} {this.props.action === 'narrator' && this.props.lang === 'fr' ? ' 🇫🇷':null}
              <Text selectable={false} style={[this.props.styles.menuItemCount, (this.props.fontsLoaded ? this.props.styles.menuItemCountFF : null)]}>
                {this.props.action === 'sourate_list' ? (' ('+this.props.souratesLength+')') :
                  this.props.action === 'ahzab_list' ? (' ('+this.props.ahzabLength+')') :
                    this.props.action === 'narrator_list' ? (' ('+this.props.narratorsLength+')') :
                    this.props.action === 'bookmark_list' ? (' ('+this.props.bookmarksLength+')') :
                    null
                }
              </Text>
            </Text>
            {this.props.isParent ? <View style={this.props.styles.menuSVGRightArrowIconContainer}><View style={this.props.styles.menuSVGRightArrowIcon}><Icon.ChevronRightIcon size={20} color='#ffc029'/></View></View> : null}
            {this.props.downloadable ?
              (this.state.downloadOffset === 114 ?
                null
                :
                <View style={this.props.styles.menuProgressContainer}>
                  <AnimatedCircularProgress fill={(this.state.downloadOffset / 114) * 100} width={4} backgroundColor='#681200bb' tintColor='#ffc029' size={36} lineCap='round'/>
                  {this.state.downloading ?
                    <ActivityIndicator style={this.props.styles.menuDownloadLoader} color={this.props.styles.menuLoaderColor}></ActivityIndicator>
                    :
                    <Pressable style={this.props.styles.menuSVGDownloadIconContainer} onPress={() => {this.onDownloadPress();}}><Icon.CloudArrowDownIcon size={20} color='#ffc029'/></Pressable>
                  }
                </View>
              )
              :
              null
            }
          </Pressable>}
      </View>
    );
  }
}
