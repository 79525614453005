import React, { Component } from 'react';

import {
  View,
  Text,
  StyleSheet,
  Image,
  TouchableOpacity,
} from 'react-native';
import Slider from '@react-native-community/slider';


function secondsToHms(d) {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor(d % 3600 / 60);
  var s = Math.floor(d % 3600 % 60);

  return (h > 0 ? String(h).padStart(2, '0') + ':' : '') + String(m).padStart(2, '0') + ":" + String(s).padStart(2, '0');
}

const SeekBar = ({
  trackLength,
  currentPosition,
  onSeek,
  disabled,
  styles,
  fontsLoaded,
}) => {
  const currentPositionFloor = Math.floor(currentPosition);
  const elapsed = secondsToHms(currentPositionFloor);
  const total = secondsToHms(trackLength);
  return (
    <View style={styles.playerSeekBar}>
      <Slider
        maximumValue={trackLength}
        onSlidingComplete={onSeek}
        value={currentPosition}
        minimumTrackTintColor={disabled ? '#ffffff33':'#ffffee'}
        maximumTrackTintColor={'#ffffff33'}
        thumbStyle={disabled ? styles.playerThumbDisabled : styles.playerThumb}
        thumbImage={disabled ? require('../../../assets/images/light_brown_dot.png') : require('../../../assets/images/white_dot.png')}
        disabled={disabled}
      />
      <View>
        <Text selectable={false} style={[(disabled ? styles.playerTimeDisabled:styles.playerTime), (fontsLoaded ? styles.contentFr : null), ]}>{elapsed + ' / ' + total}</Text>
      </View>
    </View>
  );
};

export default SeekBar;

