import React, { Component } from 'react';

import {
  View,
  Text,
  StyleSheet,
  Image,
  TouchableHighlight,
  TouchableOpacity,
  Dimensions,
} from 'react-native';


const TrackDetails = ({
  title,
  artist,
  onAddPress,
  onMorePress,
  onTitlePress,
  onArtistPress,
  styles,
  fontsLoaded,
}) => (
  <View>
    <Text selectable={false} numberOfLines={1} style={[styles.playerSourateTitle, (fontsLoaded ? styles.playerSourateTitleFF : null)]} onPress={onTitlePress}>{title}</Text>
    <Text selectable={false} style={[styles.playerNarrator, (fontsLoaded ? styles.contentFr : null)]} onPress={onArtistPress}>{artist}</Text>
  </View>
);

export default TrackDetails;
