import React, {Component} from 'react';
import {
  Platform,
} from 'react-native';
import * as SQLite from 'expo-sqlite';
import { Asset } from 'expo-asset';
import * as FileSystem from 'expo-file-system';

import summaryData from '../../assets/json/summary.json';

const isNative = Platform.OS !== 'web';

export async function openDatabase(update) {
  // await FileSystem.deleteAsync(FileSystem.documentDirectory + 'Qaraa');
  // await FileSystem.deleteAsync(FileSystem.documentDirectory + 'SQLite');
  // return;
  if (isNative) {
    // are we updating?
    if ((await FileSystem.getInfoAsync(FileSystem.documentDirectory + 'SQLite/updating.txt')).exists) {
      return null;
    }
    var mediaDir = FileSystem.documentDirectory + 'Qaraa/media/';
    var summaryFile = mediaDir + 'summary.json';
    if ((await FileSystem.getInfoAsync(mediaDir)).exists === false) {
      await FileSystem.makeDirectoryAsync(mediaDir, {intermediates: true});
    }
    if (!(await FileSystem.getInfoAsync(FileSystem.documentDirectory + 'SQLite')).exists) {
      await FileSystem.makeDirectoryAsync(FileSystem.documentDirectory + 'SQLite');
      // download from module, this is the very first application launch
      await FileSystem.downloadAsync(
        Asset.fromModule(require('../../assets/sqlite/data.sqlite')).uri,
        FileSystem.documentDirectory + 'SQLite/data.sqlite'
      );

      // local summary
      await FileSystem.writeAsStringAsync(summaryFile, JSON.stringify(summaryData));
    }
    if (update || !(await FileSystem.getInfoAsync(FileSystem.documentDirectory + 'SQLite/data.sqlite')).exists) {
      // file to indicate we're updating
      await FileSystem.writeAsStringAsync(FileSystem.documentDirectory + 'SQLite/updating.txt', 'updating');

      // update from distance
      await FileSystem.downloadAsync(
        'https://qaraa.fr/api/sqlite',
        FileSystem.documentDirectory + 'SQLite/data.sqlite'
      );
      // download summary also
      await FileSystem.downloadAsync('https://qaraa.fr/api/summary', summaryFile);

      // remove updating file now
      await FileSystem.deleteAsync(FileSystem.documentDirectory + 'SQLite/updating.txt');
    }
    return SQLite.openDatabase('data.sqlite');
  }
};

export function query(type, id) {
    var query = 'SELECT \
    sourate.number as sourateNumber, \
    verset.number, \
    translationAr.content as versetContent_ar, \
    translationFr.content as versetContent_fr, \
    translationTr.content as versetContent_tr, \
    translationSAr.content as sourateName_ar, \
    translationSFr.content as sourateName_fr, \
    sourateNar1.time_codes as timeCodes1, \
    sourateNar2.time_codes as timeCodes2, \
    sourateNar3.time_codes as timeCodes3, \
    sourateNar4.time_codes as timeCodes4, \
    sourateNar5.time_codes as timeCodes5, \
    sourateNar6.time_codes as timeCodes6, \
    sourateNar7.time_codes as timeCodes7, \
    sourateNar8.time_codes as timeCodes8, \
    sourateNar9.time_codes as timeCodes9 \
    FROM verset \
    INNER JOIN sourate ON sourate.id = verset.sourate_id \
    INNER JOIN translation translationAr ON translationAr.text_entry_id = verset.content AND translationAr.language_id = 1 \
    INNER JOIN translation translationFr ON translationFr.text_entry_id = verset.content AND translationFr.language_id = 2 \
    INNER JOIN translation translationTr ON translationTr.text_entry_id = verset.content AND translationTr.language_id = 3 \
    INNER JOIN translation translationSAr ON translationSAr.text_entry_id = sourate.name AND translationSAr.language_id = 1 \
    INNER JOIN translation translationSFr ON translationSFr.text_entry_id = sourate.name AND translationSFr.language_id = 2 \
    INNER JOIN sourate_narrator sourateNar1 ON sourateNar1.sourate_id = verset.sourate_id AND sourateNar1.narrator_id = 1 \
    INNER JOIN sourate_narrator sourateNar2 ON sourateNar2.sourate_id = verset.sourate_id AND sourateNar2.narrator_id = 2 \
    INNER JOIN sourate_narrator sourateNar3 ON sourateNar3.sourate_id = verset.sourate_id AND sourateNar3.narrator_id = 3 \
    INNER JOIN sourate_narrator sourateNar4 ON sourateNar4.sourate_id = verset.sourate_id AND sourateNar4.narrator_id = 4 \
    INNER JOIN sourate_narrator sourateNar5 ON sourateNar5.sourate_id = verset.sourate_id AND sourateNar5.narrator_id = 5 \
    INNER JOIN sourate_narrator sourateNar6 ON sourateNar6.sourate_id = verset.sourate_id AND sourateNar6.narrator_id = 6 \
    INNER JOIN sourate_narrator sourateNar7 ON sourateNar7.sourate_id = verset.sourate_id AND sourateNar7.narrator_id = 7 \
    INNER JOIN sourate_narrator sourateNar8 ON sourateNar8.sourate_id = verset.sourate_id AND sourateNar8.narrator_id = 8 \
    INNER JOIN sourate_narrator sourateNar9 ON sourateNar9.sourate_id = verset.sourate_id AND sourateNar9.narrator_id = 9';
    if (type == 'sourate') {
        query += ' WHERE verset.sourate_id = ' + id;
    }
    if (type == 'hizb') {
        query += ' WHERE verset.hizb >= ' + (id*4 - 3) + ' AND verset.hizb <= ' + id*4;
    }
    return query;
};

export function resultTransform(result) {
    var items = {};
    result.forEach((verset) => {
      var sourateNumber = verset.sourateNumber;
      if (items[sourateNumber] === undefined) {
        items[sourateNumber] = {};
      }
      if (items[sourateNumber]['versets'] === undefined) {
        items[sourateNumber]['versets'] = [];
        items[sourateNumber]['versets'][0] = {};
        items[sourateNumber]['versets'][0]['sourateNames'] = {};
        items[sourateNumber]['versets'][0]['sourateNames'][1] = verset.sourateName_ar;
        items[sourateNumber]['versets'][0]['sourateNames'][2] = verset.sourateName_fr;
        items[sourateNumber]['versets'][0]['sourateNumber'] = sourateNumber;
      }
      if (items[sourateNumber]['timeCodes'] === undefined) {
        items[sourateNumber]['timeCodes'] = {};
        for (var i = 1; i <= 9; i++) {
          items[sourateNumber]['timeCodes'][i] = JSON.parse(verset['timeCodes'+i]);
        }
      }
      verset[1] = verset.versetContent_ar;
      delete verset.versetContent_ar;
      verset[2] = verset.versetContent_fr;
      delete verset.versetContent_fr;
      verset[3] = verset.versetContent_tr;
      delete verset.versetContent_tr;
      delete verset.sourateName_ar;
      delete verset.sourateName_fr;
      verset['arabicNumber'] = (''+verset.number).replace(/\d/g,d=>"٠١٢٣٤٥٦٧٨٩"[d]);
      for (var i = 1; i <= 9; i++) {
        delete verset['timeCodes'+i];
      }
      items[sourateNumber]['versets'].push(verset);
    })
    return items;
};