import React, {Component} from 'react';
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  Text,
  View,
  Image,
  ImageBackground,
  Platform,
  Animated,
  Easing,
  useColorScheme,
  Linking,
  Button,
  TouchableOpacity,
  Pressable,
  Alert,
} from 'react-native';
import * as Icon from "react-native-heroicons/outline";
import ViewShot from 'react-native-view-shot-with-web-support';
import * as Sharing from 'expo-sharing';
import * as Device from 'expo-device';
import * as FileSystem from 'expo-file-system';
import * as MailComposer from 'expo-mail-composer';
import { Asset } from 'expo-asset';

import versetCornerUL from '../../assets/images/verset_corner_upper_left.png';
import versetCornerLL from '../../assets/images/verset_corner_lower_left.png';
import versetCornerUR from '../../assets/images/verset_corner_upper_right.png';
import versetCornerLR from '../../assets/images/verset_corner_lower_right.png';
import versetBookmark from '../../assets/images/bookmark.png';

const isNative = Platform.OS !== 'web';

export default class Verset extends Component {
  state = {
    snapshot: false,
    msg: false,
    showControls: false,
  };
  viewRef = null
  componentDidMount = async () => {
    this.viewRef = React.createRef();
    await this.cacheImages([
      require('../../assets/images/verset_corner_upper_left.png'),
      require('../../assets/images/verset_corner_lower_left.png'),
      require('../../assets/images/verset_corner_upper_right.png'),
      require('../../assets/images/verset_corner_lower_right.png'),
      require('../../assets/images/bookmark.png'),

    ]);
  }

  cacheImages = async (images) => {
    return images.map(image => {
      if (typeof image === 'string') {
        return Image.prefetch(image);
      } else {
        return Asset.fromModule(image).downloadAsync();
      }
    });
  }

  filterNum = function(num, arabic) {
    if (num === 0 || num === '٠') {
      return null;
    }
    if (arabic) {
      return num
    }
    return num + ' - ';
  }

  onPress = (longPress) => {
    this.props.selectOneVerset(this.props.sourate, this.props.item['number'], false, longPress);
  }

  share = () => {
    var T = this;
    this.setState({
      snapshot: true,
    }, function() {
      setTimeout(function() {
        T.viewRef.current.capture()
          .then(uri => {
            if (isNative) {
              Sharing.shareAsync('file://' + uri)
                .then((res) => {
                  T.setState({
                    snapshot: false,
                  });
                  T.props.shareDone();
                })
                .catch((err) => {
                  console.error(err);
                  T.setState({
                    snapshot: false,
                  });
                  T.props.shareDone();
                })
              ;
            } else {
              fetch('https://qaraa.fr/api/upload', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  'image': uri
                }),
              })
              .then( async (res) => {
                // get json response here
                var data = await res.json();
                await T.sendEmail('https://qaraa.fr/'+data.url);
                T.setState({
                  snapshot: false,
                });
                T.props.shareDone();
              })
              .catch((err) => {
                console.error(err);
                T.setState({
                  snapshot: false,
                });
                T.props.shareDone();
              })
            }
          })
          .catch((error) => {
            console.error(error);
            T.setState({
              snapshot: false,
            });
            T.props.shareDone();
          })
        ;
      }, 100);
    })
  }

  sendEmail = async(url) => {
    var T = this;
    var available = await MailComposer.isAvailableAsync();
    T.setState({msg: available});
    var options = {
        // body: "<html><head></head><body><p><img src='" + url + "' /></p></body></html>",
        body: "Capture du verset (s"+this.props.sourate+"v"+this.props.item['number']+") partagé : " + url,
        // isHtml: true,
      }
    let promise = new Promise((resolve, reject) => {
      MailComposer.composeAsync(options)
        .then((result) => {
          resolve(result)
        })
        .catch((error) => {
          reject(error)
        })
      }
    );
  }

  UNSAFE_componentWillReceiveProps(props) {
    var T = this;
    if (props.share) {
      this.share();
    }
  }

  render() {
    return (
      <Pressable onPress={this.onPress} onLongPress={() => {this.onPress(true);}}>
        <ViewShot ref={this.viewRef} style={
          (
            this.state.snapshot ?
            [this.props.styles.versetViewShot, this.props.theme === 'light' ? {backgroundColor: '#fef8e9'} : {backgroundColor: '#3a4151'}]
            :
            [this.props.styles.verset, this.props.theme === 'light' ? this.props.styles.shadowLight : this.props.styles.shadowDark, this.props.theme === 'light' ? {backgroundColor: '#fef8e9'} : {backgroundColor: '#282d38'},
            (this.props.selected ? (this.props.theme === 'light' ? {backgroundColor: '#d8e7e3'} : {backgroundColor: '#3a4151'}) : (
              this.props.onscreen ? null : {opacity: 0.1}
            ))] 
          )
        }>
          { this.props.onscreen ?
            <ImageBackground
              style={[this.props.styles.versetCornerUL, (this.state.snapshot ? {left:5, top: 5,} : null)]}
              source={versetCornerUL}
            />
            :
            null
          }
          { this.props.onscreen ?
            <ImageBackground
              style={[this.props.styles.versetCornerLL, (this.state.snapshot ? {left:5, bottom: 5,} : null)]}
              source={versetCornerLL}
            />
            :
            null
          }
          { this.props.onscreen ?
            <ImageBackground
              style={[this.props.styles.versetCornerUR, (this.state.snapshot ? {right:5, top: 5,} : null)]}
              source={versetCornerUR}
            />
            :
            null
          }
          { this.props.onscreen ?
            <ImageBackground
              style={[this.props.styles.versetCornerLR, (this.state.snapshot ? {right:5, bottom: 5,} : null)]}
              source={versetCornerLR}
            />
            :
            null
          }
          {this.state.snapshot === false && this.props.bookmarked ?
            <ImageBackground
              style={this.props.styles.versetBookmark}
              source={versetBookmark}
            />
            :
            null
          }
          <View>
            <Text 
              selectable={false}
              style={[this.props.styles.versetContentAr, (this.props.fontsLoaded ? this.props.styles.contentAr : null), {fontSize: this.props.textArSize}, this.props.theme === 'light' ? {color: '#000'} : {color: '#fff'}]} 
            >{this.props.item['hizb'] ? '۞' : null} {this.props.item['1']} {this.filterNum(this.props.item['arabicNumber'], true)}</Text>
            {this.props.textFr ?
              <Text 
                selectable={false}
                style={[this.props.styles.versetContentFr, (this.props.fontsLoaded ? this.props.styles.contentFr : null), {fontSize: this.props.textFrSize}, this.props.theme === 'light' ? {color: '#000'} : {color: '#fff'}]} 
              >{this.filterNum(this.props.item['number'])} {this.props.item['2']}</Text>
              :
              null
            }
            {this.props.transFr ?
              <Text 
                selectable={false}
                style={[this.props.styles.versetContentFr, (this.props.fontsLoaded ? this.props.styles.contentFr : null), {fontSize: this.props.textFrSize}, this.props.theme === 'light' ? {color: '#000'} : {color: '#fff'}]} 
              >{this.filterNum(this.props.item['number'])} {this.props.item['3'].replace(/(<([^>]+)>)/gi, '')}</Text>
              :
              null
            }
            {this.props.selected ? 
              this.state.snapshot ?
                <View style={this.props.styles.versetControlsContainer, this.props.styles.watermarkContainer}>
                  <Text selectable={false} style={[this.props.styles.contentFr, this.props.styles.watermark, this.props.theme === 'light' ? {color: '#000'} : {color: '#fff'}]}>© qaraa - s{this.props.sourate} v{this.props.item['number']}</Text>
                </View>
                :
                null
              :
              null
            }
          </View>
        </ViewShot>
      </Pressable>
    );
  }
};
