import React, { Component } from 'react';
import {
  View,
  Text,
  StatusBar,
  TouchableOpacity,
  Animated,
  Easing,
} from 'react-native';
import {Platform, Dimensions} from 'react-native';
import {getStatusBarHeight} from 'react-native-status-bar-height';
import * as Device from 'expo-device';
import Context from '../../contexts/context';
import { Audio } from 'expo-av';
import * as Icon from "react-native-heroicons/outline";

import TrackDetails from './TrackDetails';
import SeekBar from './SeekBar';
import Controls from './Controls';

const isNative = Platform.OS !== 'web';

const screenHeight = Dimensions.get('window').height;
const deviceHeight = Dimensions.get('screen').height;
const bottomNavBarHeight = 0;

export default class Player extends Component {

  static contextType = Context;
  sound = null;
  firstPlayingCall = false;

  loops = ['none', 'whole', 'one'];
  speeds = [1, 1.25, 1.5, 0.75];

  constructor(props) {
    super(props);
    this.state = {
      paused: true,
      currentPosition: 0,
      currentVerset: -1,
      errorMsg: '',
      minimized: false,
      loop: 0,
      speed: 0,
    };
  }

  playSound = async () => {
    var T = this;
    // new sound or resume ?
    this.firstPlayingCall = false;
    if (this.sound === null) {
      this.firstPlayingCall = true;
      // new sound
      await Audio.setAudioModeAsync({ playsInSilentModeIOS: true, staysActiveInBackground: true });
      this.sound = new Audio.Sound();
      this.sound.loadAsync(
        {
          uri: this.props.sourates[this.props.currentSourate].audioUrl
        },
        {
          progressUpdateIntervalMillis: 100,
          isLooping: false,
        },
      ).then((e) => {
        setTimeout(function() {
          if (T.sound) {
            T.sound.setOnPlaybackStatusUpdate(T.onPlaybackStatusUpdate);
          }
        }, 200);
      }).catch((e) => {
        this.setState({ errorMsg: '(à télécharger)', });
      });
    } else {
      this.sound.setStatusAsync({
        shouldPlay: true,
        positionMillis: this.state.currentPosition * 1000
      });
      this.setState({ paused: false, }, function() {
        setTimeout(function() {
          if (T.sound) {
            T.sound.setOnPlaybackStatusUpdate(T.onPlaybackStatusUpdate);
          }
        }, 200);
      });
    }
  }

  pauseSound = async () => {
    if (this.sound !== null) {
      await this.sound.pauseAsync();
    }
    await this.setState({ paused: true, })
  }

  stopSound = async (replay) => {
    var T = this;
    if (this.sound !== null) {
      this.sound.setOnPlaybackStatusUpdate(null);
      await this.sound.unloadAsync()
      .then((e) => {
        this.sound = null;
      })
    }
    this.setState({ paused: true, }, function() {
      if (replay) {
        T.playSound();
      }
    });
  }

  onPlaybackStatusUpdate = playbackStatus => {
    if (!playbackStatus.isLoaded && !playbackStatus.isLooping) {
      // reset sound
      this.stopSound();
      if (playbackStatus.error) {
        this.setState({ errorMsg: '(à télécharger)', });
      }
    } else {
      if (this.firstPlayingCall) {
        this.firstPlayingCall = false;
        this.sound.setStatusAsync({
          shouldPlay: true,
          positionMillis: this.state.currentPosition * 1000
        });
        this.setState({ paused: false, });
        return;
      }
      if (playbackStatus.isPlaying) {
        if (playbackStatus.rate !== this.speeds[this.state.speed]) {
          this.sound.setStatusAsync({
            rate: this.speeds[this.state.speed],
            shouldCorrectPitch: true,
            pitchCorrectionQuality: Audio.PitchCorrectionQuality.High,
          });
          return;
        }
        if ((playbackStatus.isLooping && this.state.loop !== 1)
          || (playbackStatus.isLooping === false && this.state.loop === 1)) {
          this.sound.setStatusAsync({
            isLooping: this.state.loop === 1
          });
          return;
        }

        // timecodes
        for (var i in this.props.timeCodes) {
          // loopMode
          var position = (playbackStatus.positionMillis / 1000) + 0.1;
          if (position < this.props.timeCodes[i]) {
            // loop one verset ?
            if (this.state.currentVerset !== -1 && this.state.loop == 2 && this.state.currentVerset !== (parseInt(i) - 1)) {
              // go back to the start of verset
              this.seek(this.props.timeCodes[Math.max(0, parseInt(i)-2)], true);
              break;
            }
            if (this.props.currentVerset !== (parseInt(i) - 1)) {
              this.props.selectOneVerset(this.props.currentSourate, (parseInt(i) - 1), true);
            }
            break;
          }
        }
        this.setState({ currentPosition: playbackStatus.positionMillis / 1000 });
      }
  
      if (playbackStatus.isBuffering) {
        // Update your UI for the buffering state
      }
  
      if (playbackStatus.didJustFinish && !playbackStatus.isLooping) {
        // The player has just finished playing
        if (this.state.loop == 2 && this.props.timeCodes) {
          // we loop on the last verse
          this.seek(this.props.timeCodes[Object.keys(this.props.timeCodes).length-2], true);
        }
      }
    }
  }

  seek = async (time, auto) => {
    if (this.state.errorMsg !== '') {
      return;
    }
    if (this.sound !== null) {
      await this.sound.setOnPlaybackStatusUpdate(null);
    }
    this.setState({
      currentPosition: time,
    }, function() {
      if (this.sound !== null) {
        this.playSound();
      }
    });
  }

  toggleMinimized = () => {
    var playerYPosVal = 140 + bottomNavBarHeight;
    if (this.state.minimized) {
      playerYPosVal = 0;
    }
    Animated.timing(this.context.playerYPos, {
      toValue: playerYPosVal,
      easing: Easing.out(Easing.ease),
      duration: 300,
      useNativeDriver: true,
    }).start(() => {
      this.setState({
        minimized: !this.state.minimized,
      });
    });
  }

  loopChange = async () => {
    // next loop
    var nextLoop = this.state.loop + 1;
    if (nextLoop >= this.loops.length) {
      nextLoop = 0;
    }
    // loop verset ?
    if (nextLoop == 2) {
      this.state.currentVerset = this.props.currentVerset;
    }
    this.setState({ loop: nextLoop, })
  }

  speedChange = async () => {
    // next speed
    var nextSpeed = this.state.speed + 1;
    if (nextSpeed >= this.speeds.length) {
      nextSpeed = 0;
    }
    this.setState({ speed: nextSpeed, })
  }

  UNSAFE_componentWillReceiveProps(props) {
    var T = this;
    var states = {
      currentVerset: props.currentVerset,
    };

    if (props.currentPosition !== null) {
      states.currentPosition = props.currentPosition;
    }

    var stop = false;
    var replay = false;
    // user clicked ?
    if (props.autoVersetChange === false && props.currentVerset !== this.state.currentVerset) {
      if (T.sound !== null) {
        stop = true;
        replay = (T.state.paused === false);
      }
    }

    // narrator has changed? verify audio
    if (stop === false && this.props.sourates && props.sourates && this.props.sourates[this.props.currentSourate].audioUrl !== props.sourates[props.currentSourate].audioUrl) {
      stop = true;
    }

    // visual bug
    if (this.state.minimized === false && this.context.playerYPos._value > 0) {
      states.minimized = true;
    }

    this.setState(states, function() {
      if (stop) {
        T.stopSound(replay);
      }
    });
  }

  componentWillUnmount = async () => {
    this.stopSound();
  }

  render() {
    return (
      <Animated.View style={[this.props.styles.playerContainer, {transform: [{translateY: this.context.playerYPos}]}]}>
        <View style={this.props.styles.player}>
          <TrackDetails
            styles={this.props.styles}
            fontsLoaded={this.props.fontsLoaded}
            title={this.props.sourates === null ? '...' : this.props.sourates[this.props.currentSourate].title}
            artist={this.props.sourates === null ? '...' : this.props.sourates[this.props.currentSourate].artist + (this.state.errorMsg === '' ? '' : ' ' + this.state.errorMsg)}
          />
          {(isNative === false && (Device.osName === 'Mac OS' || Device.osName === 'Windows')) === false ?
            <TouchableOpacity activeOpacity={1} onPress={() => {this.toggleMinimized();}}>
              <View style={this.props.styles.playerMinimizeIconContainer}>
                {this.state.minimized ? <Icon.ChevronUpDownIcon size={30} color='#fff' /> : <Icon.ChevronUpDownIcon size={30} color='#fff' />}
              </View>
            </TouchableOpacity>
            :
            <View style={[this.props.styles.playerMinimizeIconContainer, {top: -10, height: 10,}]}>
            </View>
          }
          <SeekBar
            styles={this.props.styles}
            fontsLoaded={this.props.fontsLoaded}
            onSeek={this.seek.bind(this)}
            trackLength={this.props.sourates ? this.props.sourates[this.props.currentSourate].totalLength : 1}
            currentPosition={this.state.currentPosition}
            disabled={this.state.errorMsg !== ''}
          />
          <Controls
            styles={this.props.styles}
            fontsLoaded={this.props.fontsLoaded}
            loopMode={this.loops[this.state.loop]}
            speed={this.speeds[this.state.speed]}
            onPressPlay={() => this.playSound()}
            onPressPause={() => this.pauseSound()}
            onPressLoop={() => this.loopChange()}
            onPressSpeed={() => this.speedChange()}
            paused={this.state.paused}
            disabled={this.state.errorMsg !== ''}
          />
        </View>
      </Animated.View>
    );
  }
}
