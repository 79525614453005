import {Platform, StyleSheet, Dimensions} from 'react-native';
import {getStatusBarHeight} from 'react-native-status-bar-height';

const isNative = Platform.OS !== 'web';

export const loaderColor = '#222';
export const menuLoaderColor = '#ffc029';
export const versetContentArFontSizeDefault = 40;
export const versetContentArFontSizes = [30, 40, 50, 60];
export const versetContentFrFontSizeDefault = 20;
export const versetContentFrFontSizes = [17, 20, 22, 25];

export function computedStyles() {
  var displayWidth = Dimensions.get('window').width;
  var screenHeight = Dimensions.get('window').height;
  var deviceHeight = Dimensions.get('screen').height;
  var bottomNavBarHeight =
    Platform.OS === 'ios'
      ? 44 / 2
      : getStatusBarHeight();
  var statusBarHeight = 
    Platform.OS === 'ios'
      ? getStatusBarHeight()
      : 0;
  var displayHeight = screenHeight - statusBarHeight - bottomNavBarHeight;
  var styles = StyleSheet.create({
    scrollView: {
      flex: 1,
    },
    mainContainer: {
      top: statusBarHeight,
      flex: 1,
      position: 'absolute',
      height: displayHeight,
      width: displayWidth,
    },
    main: {
      position: 'absolute',
      height: displayHeight,
      width: displayWidth,
    },
    logoContainer: {
      position: 'absolute',
      width: displayWidth,
      height: 68,
      flex: 1,
      alignItems: 'flex-end', // horizontal
      justifyContent: 'center', // vertical
    },
    logo: {
      width: 46,
      height: 46,
      left: -15,
    },
    loadingTextContainer: {
      position: 'absolute',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: 35,
      width: displayWidth,
      height: displayHeight,
    },
    versetCornerUL: {
      position: 'absolute',
      width: 40,
      height: 40,
      top: 0,
      left: 0,
    },
    versetCornerLL: {
      position: 'absolute',
      width: 40,
      height: 40,
      bottom: 0,
      left: 0,
    },
    versetCornerUR: {
      position: 'absolute',
      width: 40,
      height: 40,
      top: 0,
      right: 0,
    },
    versetCornerLR: {
      position: 'absolute',
      width: 40,
      height: 40,
      bottom: 0,
      right: 0,
    },
    versetBookmark: {
      position: 'absolute',
      width: 40,
      height: 40,
      top: 0,
      right: 40,
    },
    menuCornerUL: {
      position: 'absolute',
      width: 60,
      height: 60,
      top: 0,
      left: 0,
    },
    menuCornerLL: {
      position: 'absolute',
      width: 60,
      height: 60,
      bottom: 0,
      left: 0,
    },
    menuCornerUR: {
      position: 'absolute',
      width: 60,
      height: 60,
      top: 0,
      right: 0,
    },
    menuCornerLR: {
      position: 'absolute',
      width: 60,
      height: 60,
      bottom: 0,
      right: 0,
    },
    leather: {
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
    menuContainer: {
      top: statusBarHeight,
      position: 'absolute',
      height: displayHeight,
      width: 350,
      overflow: 'hidden',
    },
    menuSlideContainer: {
      height: displayHeight,
      width: 350,
    },
    menuButtonContainer: {
      top: statusBarHeight,
      position: 'absolute',
      width: 68,
      height: 68,
      flex: 1,
      alignItems: 'flex-start', // horizontal
      justifyContent: 'center', // vertical
    },
    menuButton: {
      left: 15,
    },
    menuScrollView: {
      position: 'absolute',
      flex: 1,
      height: displayHeight - 80,
      top: 40,
      width: 350,
      marginBottom: 200,
    },
    menuItem: {
      width: 350,
      paddingVertical: 14,
      paddingLeft: 45,
      color: '#ffc029',
      fontSize: 20,
    },
    menuTextHL: {
      textShadowColor: '#ddd71d',
      textShadowRadius: 20,
      textShadowOffset: {
        height: 0,
        width: 0,
      },
      elevation: 5,
    },
    menuTextHLFF: {
      fontFamily: 'ProximaBold',
      fontFamily: 'DINNextLTProBold',
    },
    menuTextDisabled: {
      color: '#ffc02966',
    },
    menuItemWithoutIcon: {
      paddingHorizontal: 30,
    },
    menuItemHover: {
      backgroundColor: '#681200aa',
    },
    menuItemLabel: {
      width: 350,
      paddingVertical: 14,
      paddingHorizontal: 30,
      color: '#ffc029',
      fontSize: 30,
    },
    menuItemLabelFF: {
      fontFamily: 'ProximaBold',
      fontFamily: 'DINNextLTProBold',
    },
    menuItemBack: {
      width: 350,
      paddingVertical: 14,
      paddingHorizontal: 30,
      color: '#ffc029',
      fontSize: 15,
    },
    menuItemCount: {
      fontSize: 15,
    },
    menuItemCountFF: {
      fontFamily: 'ProximaBold',
      fontFamily: 'DINNextLTProBold',
    },
    menuSVGIconContainer: {
      position: 'absolute',
    },
    menuSVGIcon: {
      top: 15,
      left: 18,
    },
    menuSVGIcon2: {
      top: 17,
      left: 30,
    },
    menuSVGRightArrowIconContainer: {
      position: 'absolute',
    },
    menuSVGRightArrowIcon: {
      right: -320,
      top: 15,
    },
    menuSVGCloudIcon: {
      right: -270,
      top: 15,
    },
    menuSVGDownloadIconContainer: {
      position: 'absolute',
      right: 8,
      top: 7,
    },
    menuProgressContainer: {
      position: 'absolute',
      right: 10,
      top: 7,
    },
    salamContainer: {
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: 16,
      flex: 1,
    },
    salam: {
      width: displayWidth - 10,
      maxWidth: 950,
      margin: 10,
      padding: 10,
    },
    salamAr: {
      textAlign: 'center',
      lineHeight: 50,
      fontSize: 35,
      marginTop: 10,
    },
    salamFr: {
      textAlign: 'center',
      lineHeight: 30,
      fontSize: 15,
      marginBottom: 10,
    },
    presentation: {
      fontSize: 14,
      textAlign: 'center',
      marginHorizontal: 25,
    },
    greetingsAr: {
      textAlign: 'center',
      lineHeight: 50,
      fontSize: 25,
      marginHorizontal: 25,
    },
    greetingsFr: {
      textAlign: 'center',
      lineHeight: 30,
      fontSize: 14,
      marginHorizontal: 25,
    },
    linkFF: {
      fontFamily: 'ProximaBold',
      fontFamily: 'DINNextLTProBold',
    },
    overScreen: {
      top: statusBarHeight,
      position: 'absolute',
      width: displayWidth,
      height: displayHeight,
    },
    sourateViewContainer: {
      position: 'absolute',
      top: 40,
    },
    sourateScrollViewContainer: {
      width: displayWidth,
      height: displayHeight - 40,
    },
    sourateContainer: {
      alignItems: 'center',
    },
    verset: {
      width: displayWidth - 10,
      maxWidth: 950,
      margin: 5,
      padding: 10,
    },
    versetViewShot: {
      width: displayWidth,
      maxWidth: 960,
      margin: 5,
      padding: 10,
    },
    versetContentAr: {
      margin: 10,
    },
    contentAr: {
      fontFamily: 'KFGQPC Uthmanic Script HAFS',
      writingDirection: 'rtl',
    },
    versetContentFr: {
      margin: 10,
      textAlign: 'left',
    },
    contentFr: {
      fontFamily: 'Proxima',
      fontFamily: 'DINNextLTPro',
      writingDirection: 'ltr',
    },
    versetControlsAlertContainer: {
      position: 'absolute',
      top: -40,
      width: displayWidth,
      height: displayHeight,
    },
    versetControlsAlert: {
      width: 350,
    },
    versetControlsContainer: {
      flexDirection: 'column',
      flexShrink: 1,
      justifyContent: 'center', // vertical
    },
    versetControls: {
      flexDirection: 'row',
      width: 250,
      height: 50,
      alignItems: 'center', // vertical
      justifyContent: 'flex-start', // horizontal
    },
    versetControlsText: {
      marginLeft: 20,
      fontSize: 18,
    },
    watermarkContainer: {
      bottom: -5,
    },
    watermark: {
      fontSize: 10,
      marginTop: 25,
      textAlign: 'center',
    },
    sourateTitle: {
      lineHeight: 68,
      height: 68,
      fontSize: 40,
      textAlign: 'center',
    },
    nextPrevButtonContainer: {
      position: 'relative',
      width: displayWidth,
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'center',
      marginBottom: 160,
    },
    nextPrevButton: {
      padding: 10,
      width: 180,
      margin: 10,
      marginBottom: 10 + (bottomNavBarHeight),
    },
    nextPrevText: {
      width: 120,
      lineHeight: 40,
      fontSize: 18,
      textAlign: 'center',
    },
    nextPrevSVGRightLeftArrowIconContainer: {
      width: 160,
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'center',
    },
    nextPrevSVGRightLeftArrowIcon: {
      top: 10,
      width: 40,
      right: -10,
    },
    loaderContainer: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      top: -20,
    },
    loader: {
      padding: 12,
      borderRadius: 12,
    },
    menuLoaderContainer: {
      position: 'absolute',
      width: 350,
      height: displayHeight,
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    menuLoader: {
      padding: 12,
      backgroundColor: '#681200aa',
      borderRadius: 12,
    },
    menuDownloadLoader: {
      position: 'absolute',
      right: 8,
      top: 8,
    },
    helpContainer: {
      flex: 1,
      height: displayHeight,
    },
    helpLine: {
      fontSize: 15,
      textAlign: 'center',
      marginHorizontal: 20,
    },
    screenTitleContainer: {
      width: displayWidth,
      textAlign: 'center',
    },
    screenTitle: {
      lineHeight: 68,
      height: 68,
      fontSize: 25,
      textAlign: 'center',
    },
    settingsContainer: {
      position: 'absolute',
      alignItems: 'center',
      justifyContent: 'center',
      left: 0,
      right: 0,
    },
    settingsLine: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      height: 40,
      fontSize: 18,
      borderRadius: 5,
    },
    settingsSwitchText: {
      height: 20,
      lineHeight: 20,
      textAlign: 'center',
      width: 20,
      marginTop: 5,
    },
    settingsLetterUpText: {
      height: 25,
      lineHeight: 30,
      fontSize: 30,
      width: 30,
      textAlign: 'center',
      left: -5,
      color: '#ffffff',
    },
    settingsLetterDownText: {
      height: 20,
      lineHeight: 26,
      fontSize: 15,
      width: 30,
      textAlign: 'center',
      left: -5,
      color: '#ffffff',
    },
    settingsLetterUpTextAr: {
      height: 50,
      lineHeight: 60,
      fontSize: 30,
      width: 30,
      textAlign: 'center',
      left: 5,
      color: '#ffffff',
    },
    settingsLetterDownTextAr: {
      height: 30,
      lineHeight: 35,
      fontSize: 20,
      width: 30,
      textAlign: 'center',
      left: 5,
      color: '#ffffff',
    },
    settingsCheckboxText: {
      height: 20,
      lineHeight: 20,
      fontSize: 15,
      textAlign: 'center',
    },
    playerContainer: {
      position: 'absolute',
      alignItems: 'center',
      justifyContent: 'center',
      left: 0,
      right: 0,
      bottom: 5,
    },
    player: {
      backgroundColor: '#511100f8',
      shadowColor: '#000000',
      shadowOpacity: 0.15,
      shadowRadius: 10,
      shadowOffset: {
        height: 5,
        width: 0,
      },
      elevation: 5,
      width: displayWidth - 20,
      height: 140 + (bottomNavBarHeight),
      maxWidth: 930,
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
    },
    playerSourateTitle: {
      width: '100%',
      textAlign: 'center',
      color: '#ffffff',
      fontSize: 24,
      paddingHorizontal: 10,
    },
    playerSourateTitleFF: {
      fontFamily: 'ProximaBold',
      fontFamily: 'DINNextLTProBold',
    },
    playerNarrator: {
      width: '100%',
      color: '#ffffffbb',
      fontSize: 15,
      fontWeight: '100',
      textAlign: 'center',
    },
    playerTime: {
      width: '100%',
      color: '#ffffff',
      fontSize: 16,
      fontWeight: '100',
      textAlign: 'right',
    },
    playerTimeDisabled: {
      width: '100%',
      color: '#ffffff33',
      fontSize: 16,
      fontWeight: '100',
      textAlign: 'right',
    },
    playerSeekBar: {
      paddingTop: 5,
      paddingHorizontal: 10,
    },
    playerThumb: {
      width: 10,
      height: 10,
      borderRadius: 5,
      backgroundColor: '#ffffee',
    },
    playerThumbDisabled: {
      width: 10,
      height: 10,
      borderRadius: 5,
      backgroundColor: '#ffffff33',
    },
    playerControlsContainer: {
      flexDirection: 'row',
      flexShrink: 1,
      justifyContent: 'center', // vertical
    },
    playerControls: {
      width: 80,
      alignItems: 'center', // horizontal
    },
    playerSpeedControl: {
      fontSize: 22,
      lineHeight: 40,
      height: 40,
    },
    playerSpeedControlFF: {
      fontFamily: 'ProximaBold',
      fontFamily: 'DINNextLTProBold',
    },
    playerLoopCount: {
      position: 'absolute',
      color: '#ffffff',
      fontSize: 12,
      top: 14,
    },
    playerLoopCountFF: {
      fontFamily: 'ProximaBold',
      fontFamily: 'DINNextLTProBold',
    },
    playerMinimizeIconContainer: {
      position: 'absolute',
      top: -75,
      right: 0,
      width: '100%',
      height: 28,
      backgroundColor: '#511100f8',
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      flex: 1,
      alignItems: 'center', // horizontal
    },
    playerMinimizeIcon: {
      textAlign: 'center',
    },
    narratorFollowText: {
      position: 'absolute',
      fontSize: 25,
    },
    shadowLight: {
      shadowColor: '#9c7509',
      shadowOpacity: 0.1,
      shadowRadius: 3,
      shadowOffset: {
        height: 4,
        width: 2,
      },
      elevation: 5,
    },
    shadowDark: {
      shadowColor: '#0a0c11',
      shadowOpacity: 0.1,
      shadowRadius: 3,
      shadowOffset: {
        height: 4,
        width: 2,
      },
      elevation: 5,
    },
    checkbox: {
      margin: 8,
    }
  });
  
  if (isNative) {
    styles.nextPrevButtonContainer = {
      position: 'relative',
      width: displayWidth,
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'center',
      marginBottom: 155,
    };
    styles.player = {
      backgroundColor: '#511100f8',
      shadowColor: '#000000',
      shadowOpacity: 0.15,
      shadowRadius: 10,
      shadowOffset: {
        height: 3,
        width: 0,
      },
      elevation: 5,
      width: displayWidth - 20,
      height: 120 + (bottomNavBarHeight),
      maxWidth: 930,
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
    };
    styles.playerTime = {
      width: '100%',
      color: '#ffffff',
      fontSize: 16,
      fontWeight: '100',
      textAlign: 'right',
      top: Platform.OS === 'ios' ? -12 : 0,
    };
    styles.playerTimeDisabled = {
      width: '100%',
      color: '#ffffff33',
      fontSize: 16,
      fontWeight: '100',
      textAlign: 'right',
      top: -12,
    };
    styles.playerSeekBar = {
      top: Platform.OS === 'ios' ? -10 : 0,
      paddingHorizontal: 10,
    };
    styles.playerControlsContainer = {
      top: Platform.OS === 'ios' ? -18 : -5,
      flexDirection: 'row',
      flexShrink: 1,
      justifyContent: 'center', // vertical
    };
    styles.playerMinimizeIconContainer = {
      position: 'absolute',
      top: -72,
      right: 0,
      width: '100%',
      height: 34,
      backgroundColor: '#511100f8',
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      flex: 1,
      alignItems: 'center', // horizontal
    };
    styles.settingsSwitchText = {
      height: 20,
      lineHeight: 20,
      marginRight: -3,
      width: 20,
      marginTop: 11,
    };
    styles.versetControlsAlertContainer = {
      position: 'absolute',
      width: displayWidth,
      height: screenHeight,
    };
    styles.playerLoopCount = {
      position: 'absolute',
      color: '#ffffff',
      fontSize: 12,
      top: 15,
    };
  }

  return styles;
}
