import React, {Component} from 'react';
import {
  SafeAreaView,
  StatusBar,
  Text,
  View,
  Image,
  Platform,
  Animated,
  Easing,
  Dimensions,
  TouchableOpacity,
  Appearance,
  useColorScheme,
  ActivityIndicator,
  ScrollView,
  ImageBackground,
  Linking,
} from 'react-native';
import Context from './contexts/context';
import {getStatusBarHeight} from 'react-native-status-bar-height';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Asset } from 'expo-asset';
import * as SQLite from 'expo-sqlite';
import * as Font from 'expo-font';
import * as Device from 'expo-device';
import Checkbox from 'expo-checkbox';

import {openDatabase, query, resultTransform} from './database/Database';

import { loaderColor, menuLoaderColor, versetContentArFontSizeDefault, versetContentArFontSizes, versetContentFrFontSizeDefault, versetContentFrFontSizes, computedStyles } from './css/styles';

import Slider from '@react-native-community/slider';
import Switch from './views/Switch';

import Menu from './components/Menu';
import Sourate from './components/Sourate';

import versetCornerUL from '../assets/images/verset_corner_upper_left.png';
import versetCornerLL from '../assets/images/verset_corner_lower_left.png';
import versetCornerUR from '../assets/images/verset_corner_upper_right.png';
import versetCornerLR from '../assets/images/verset_corner_lower_right.png';

import logo from '../assets/images/logo.png';
import logoLight from '../assets/images/logo-light.png';

import * as Icon from "react-native-heroicons/outline";
import * as IconSolid from "react-native-heroicons/solid";

const isNative = Platform.OS !== 'web';
const screenHeight = Dimensions.get('window').height;
const deviceHeight = Dimensions.get('screen').height;

const customFonts = {
  'KFGQPC Uthmanic Script HAFS': require('../assets/fonts/Uthmanic.ttf'),
  'Proxima': require('../assets/fonts/ProximaNova-Regular.otf'),
  'ProximaBold': require('../assets/fonts/ProximaNova-Bold.otf'),
  'DINNextLTPro': require('../assets/fonts/DINNextLTPro-Regular.ttf'),
  'DINNextLTProBold': require('../assets/fonts/DINNextLTPro-Bold.ttf'),
};

const hints = [
  'Vous pouvez poser un marque-page sur un verset en faisant un appui long sur ce dernier',
  'Vous pouvez partager un verset en faisant un appui long sur ce dernier'
];

import { expo }  from '../app.json';


export class Index extends Component {
  state = {
    menuOpened: false,
    mainContentData: {
      screen: 'loading',
    },
    navigation: {},
    savedDataBeforeSettings: {},
    textArHL: true,
    textFr: false,
    transFr: false,
    textArSize: versetContentArFontSizeDefault,
    textFrSize: versetContentFrFontSizeDefault,
    narratorId: 1,
    loadingText: '',
    currentSourate: -1,
    currentHizb: -1,
    bookmarks: {},
    theme: 'light',
    portrait: true,
    darkMode: false,
    fontsLoaded: false,
    version: null,
  };
  static contextType = Context;
  sourates = [];
  narrators = [];
  styles = computedStyles();
  db = null;

  componentDidMount = async () => {
    var T = this;
    // version check
    var localVersion = await AsyncStorage.getItem('version');
    var distantVersion = undefined;
    await fetch('https://qaraa.fr/api/version')
      .then((response) => response.text())
      .then((text) => {
        distantVersion = text;
      })
      .catch((error) => {
        console.error(error);
      })
    ;
    if (localVersion === undefined) {
      localVersion = expo.version;
    }
    if (distantVersion === undefined) {
      distantVersion = expo.version;
    }
    var update = (localVersion !== String(distantVersion));
    await AsyncStorage.setItem('version', distantVersion);
    var darkMode = await AsyncStorage.getItem('darkMode') === 'true' ? true : false;
    this.setDarkMode(darkMode);
    this.setState({
      loadingText: 'Chargement de la base de données ...',
      version: distantVersion,
    });
    this.db = await openDatabase(update);
    this.setState({
      loadingText: 'Chargement des polices ...',
    });
    await Font.loadAsync(customFonts);
    await this.cacheImages([
      require('../assets/images/leather.png'),
      require('../assets/images/verset_corner_upper_left.png'),
      require('../assets/images/verset_corner_lower_left.png'),
      require('../assets/images/verset_corner_upper_right.png'),
      require('../assets/images/verset_corner_lower_right.png'),
      require('../assets/images/bookmark.png'),
      require('../assets/images/logo.png'),
      require('../assets/images/logo-light.png'),
      require('../assets/images/white_dot.png'),
      require('../assets/images/brown_dot.png'),
      require('../assets/images/light_brown_dot.png'),

    ]);
    this.loadFromStorage();
    this.setState({
      mainContentData: {
        screen: 'loading',
      }
    });
    if (isNative === false) {
      window.addEventListener('resize', this.resizeAsked.bind(this));
      window.addEventListener('popstate', this.parseUrlAndPopulateMain.bind(this));
    }
    this.resizeAsked();
  }

  componentWillUnmount() {
    if (isNative === false) {
      window.removeEventListener('resize', this.resizeAsked.bind(this));
    }
  }

  resizeAsked() {
    var displayWidth = Dimensions.get('window').width;
    var screenHeight = Dimensions.get('window').height;
    this.styles = computedStyles();
    if (!(isNative === false && (Device.osName === 'Mac OS' || Device.osName === 'Windows'))) {
      var portrait = (displayWidth < screenHeight);
      this.setState({portrait: portrait});
    } else {
      this.setState({});
    }
  }

  async cacheImages(images) {
    return images.map(image => {
      if (typeof image === 'string') {
        return Image.prefetch(image);
      } else {
        return Asset.fromModule(image).downloadAsync();
      }
    });
  }

  async loadFromStorage() {
    // get async storage
    const values = await AsyncStorage.multiGet(['navigation', 'textFr', 'transFr', 'textArSize', 'textFrSize', 'narratorId', 'darkMode']);
    // wait for this.state.bookmarks to be loaded
    this.setState({
      loadingText: 'Chargement des données de menu ...',
      fontsLoaded: true,
    });
    var T = this;
    var interval = setInterval(function () {
      if (
        T.state.bookmarks === undefined
        || T.sourates.length === 0
        || T.narrators.length === 0
      ) {
        return;
      }
      clearInterval(interval);
      T.setState({
        loadingText: '',
      });
      if (values !== null) {
        var navigation = JSON.parse(values[0][1]);
        var textFr = values[1][1] === 'true' ? true : false;
        var transFr = values[2][1] === 'true' ? true : false;
        var textArSize = parseInt(values[3][1]);
        var textFrSize = parseInt(values[4][1]);
        var narratorId = parseInt(values[5][1]);
        var darkMode = values[6][1] === 'true' ? true : false;
        T.setDarkMode(darkMode);
        if (isNative) {
          if (navigation) {
            var dataEntry = '';
            if (isNative === false) {
            }
            T.populateMain(navigation.data, textFr, transFr, textArSize, textFrSize, narratorId);
          } else {
            var data = {};
            data.entity = 'home';
            T.populateMain(data, textFr, transFr, textArSize, textFrSize, narratorId);
          }
        } else {
          T.parseUrlAndPopulateMain(textFr, transFr, textArSize, textFrSize, narratorId);
        }
      }
    }, 500);
  };

  async parseUrlAndPopulateMain(textFr, transFr, textArSize, textFrSize, narratorId) {
    if (isNative) {
      return;
    }
    // window location
    var location = window.location;
    var components = location.pathname.split('/');
    const navigationItem = await AsyncStorage.getItem('navigation');
    var navigation = JSON.parse(navigationItem);
    var data = {};
    var found = false;
    if (navigation !== null && navigation.data !== null && navigation.data.url !== null && navigation.data.url !== undefined) {
      var navComponents = navigation.data.url.split('/');
      if (navComponents[1] === components[1] && navComponents[2] === components[2]) {
        data = navigation.data;
        found = true;
      }
      navigation = {};
    }
    if (found === false) {
      switch (components[1]) {
        case '':
          // home
          data.entity = 'home';
          break;
        case 'help':
          // help
          data.entity = 'help';
          break;
        case 'sourate':
          // verset
          data.entity = 'verset';
          data.number = components[2];
          data.parentId = components[2];
          break;
        case 'hizb':
          // hizb
          data.entity = 'hizb';
          data.number = components[2];
          break;
      }
    }
    this.populateMain(data, textFr, transFr, textArSize, textFrSize, narratorId, true);
  }

  toggleMenu = () => {
    var menuXPosVal = 0;
    var mainXPosVal = 350;
    var overScreenXPosVal = 350;
    var overScreenYPosVal = 0;
    var overScreenOpacityVal = 0.5;
    if (this.state.menuOpened) {
      menuXPosVal = -350;
      mainXPosVal = 0;
      overScreenXPosVal = -10;
      overScreenYPosVal = -screenHeight;
      overScreenOpacityVal = 0;
    }
    Animated.timing(this.context.menuXPos, {
      toValue: menuXPosVal,
      easing: Easing.out(Easing.ease),
      duration: 300,
      useNativeDriver: true,
    }).start(() => {
      this.setState({
        menuOpened: !this.state.menuOpened,
      });
    });
    Animated.timing(this.context.mainXPos, {
      toValue: mainXPosVal,
      easing: Easing.out(Easing.ease),
      duration: 300,
      useNativeDriver: true,
    }).start();
    Animated.timing(this.context.overScreenXPos, {
      toValue: overScreenXPosVal,
      easing: Easing.out(Easing.ease),
      duration: 300,
      useNativeDriver: true,
    }).start();
    Animated.timing(this.context.overScreenYPos, {
      toValue: overScreenYPosVal,
      easing: Easing.out(Easing.ease),
      duration: 10,
      useNativeDriver: true,
    }).start();
    Animated.timing(this.context.overScreenOpacity, {
      toValue: overScreenOpacityVal,
      easing: Easing.out(Easing.ease),
      duration: 300,
      useNativeDriver: true,
    }).start();
  };

  setPlaceholderPage = (context, data, textFr, transFr, textArSize, textFrSize, narratorId, callback) => {
    textFr = (isNaN(textFr) || textFr == null) ? this.state.textFr : textFr;
    transFr = (isNaN(transFr) || transFr == null) ? this.state.transFr : transFr;
    textArSize = (isNaN(textArSize) || textArSize == null) ? this.state.textArSize : textArSize;
    textFrSize = (isNaN(textFrSize) || textFrSize == null) ? this.state.textFrSize : textFrSize;
    narratorId = (isNaN(narratorId) || narratorId == null) ? this.state.narratorId : narratorId;
    
    var loadingText = '';
    var currentSourate = -1;
    var currentHizb = -1;
    switch (data.entity) {
      case 'home':
        loadingText = 'Chargement de la page d\'accueil ...';
        break;
      case 'help':
        loadingText = 'Chargement de la page d\'aide ...';
        break;
      case 'verset':
        loadingText = 'Chargement de la Sourate '+data.number+' ...';
        currentSourate = parseInt(data.number);
        break;
      case 'hizb':
        loadingText = 'Chargement du Hizb '+data.number+' ...';
        currentHizb = parseInt(data.number);
        break;
    }
    this.setState({
      mainContentData: {
        screen: context,
      },
      textFr: textFr,
      transFr: transFr,
      textArSize: textArSize,
      textFrSize: textFrSize,
      narratorId: narratorId,
      loadingText: loadingText,
      currentSourate: currentSourate,
      currentHizb: currentHizb,
    }, function() {
      AsyncStorage.multiSet([
        ['textFr', ''+this.state.textFr],
        ['transFr', ''+this.state.transFr],
        ['textArSize', ''+this.state.textArSize],
        ['textFrSize', ''+this.state.textFrSize],
        ['narratorId', ''+this.state.narratorId]
      ]);
      if (callback) {
        callback(data, textFr, transFr, textArSize, textFrSize, narratorId);
      }
    });
  }

  populateMain = async (data, textFr, transFr, textArSize, textFrSize, narratorId, dontPushState) => {
    var T = this;
    var items = [];
    var q = null;
    const navigationItem = await AsyncStorage.getItem('navigation');
    var navigation = JSON.parse(navigationItem);
    if (navigation === null) {
      navigation = {};
    }
    this.db = await openDatabase(false);
      this.setPlaceholderPage('loading', data, textFr, transFr, textArSize, textFrSize, narratorId, function(data, textFr, transFr, textArSize, textFrSize, narratorId) {
        if (data !== undefined) {
          if (data.entity === 'narrator') {
            T.setState({
              narratorId: data.entityId,
            }, function() {
              AsyncStorage.setItem('narratorId', T.state.narratorId);
              if (T.state.mainContentData && T.state.mainContentData.screen == 'sourate') {
                T.populateMain(T.state.mainContentData.data);
              } else {
                T.setPlaceholderPage('home', navigation, data, textFr, transFr, textArSize, textFrSize, narratorId, function() {});
              }
            });
            return;
          }
          if (isNative === false) {
            var location = window.location;
            var components = location.pathname.split('/');
          }
          switch (data.entity) {
            case 'home':
              T.onLogoPress(dontPushState);
              return;
            case 'help':
              if (isNative === false && dontPushState !== true && components[1] !== 'help') {
                history.pushState({}, '', '/help');
              }
              navigation.data = data;
              T.setState({
                mainContentData: {
                  screen: 'help'
                },
                navigation: JSON.stringify(navigation),
                loadingText: '',
                currentSourate: -1,
                currentHizb: -1,
              }, function() {AsyncStorage.setItem('navigation', T.state.navigation)});
              return;
            case 'verset':
              if (isNative) {
                T.db.readTransaction(tx => {
                  tx.executeSql(query('sourate', data.parentId), null,
                    (txObj, {rows: {_array}}) => {
                      // render Sourate Component and populate Main
                      // save navigation
                      navigation.data = data;
                      T.setState({
                        mainContentData: {
                          screen: 'sourate',
                          data: data,
                          items: resultTransform(_array)
                        },
                        navigation: JSON.stringify(navigation),
                        loadingText: '',
                      }, function() {AsyncStorage.setItem('navigation', T.state.navigation)});
                    },
                    (txObj, error) => {console.log('Error ', error)}
                  )
                })
              } else {
                if (dontPushState !== true && (components[1] !== 'sourate' || components[2] !== data.parentId)) {
                  history.pushState({}, '', '/sourate/'+data.parentId);
                }
                fetch('https://qaraa.fr/api/sourate/'+data.parentId)
                  .then((response) => response.json())
                  .then((items) => {
                    // render Sourate Component and populate Main
                    // save navigation
                    navigation.data = data;
                    // set title
                    document.title = 'Qaraa - Sourate '+data.parentId+' : '+items[data.parentId]['versets'][0]['sourateNames'][2];
                    T.setState({
                      mainContentData: {
                        screen: 'sourate',
                        data: data,
                        items: items
                      },
                      navigation: JSON.stringify(navigation),
                      loadingText: '',
                    }, function() {AsyncStorage.setItem('navigation', T.state.navigation)});
                  })
                  .catch((error) => {
                    console.error(error);
                  })
                ;
              }
              break;
            case 'hizb':
              if (isNative) {
                T.db.readTransaction(tx => {
                  tx.executeSql(query('hizb', data.number), null,
                    (txObj, {rows: {_array}}) => {
                      // render Sourate Component and populate Main
                      // save navigation
                      navigation.data = data;
                      T.setState({
                        mainContentData: {
                          screen: 'sourate',
                          data: data,
                          items: resultTransform(_array)
                        },
                        navigation: JSON.stringify(navigation),
                        loadingText: '',
                      }, function() {AsyncStorage.setItem('navigation', T.state.navigation)});
                    },
                    (txObj, error) => {console.log('Error ', error)}
                  )
                })
              } else {
                if (dontPushState !== true && (components[1] !== 'hizb' || components[2] !== data.number)) {
                  history.pushState({}, '', '/hizb/'+data.number);
                }
                fetch('https://qaraa.fr/api/hizb/'+data.number)
                  .then((response) => response.json())
                  .then((items) => {
                    // render Sourate Component and populate Main
                    // save navigation
                    navigation.data = data;
                    // set title
                    document.title = 'Qaraa - Hizb '+data.number;
                    T.setState({
                      mainContentData: {
                        screen: 'sourate',
                        data: data,
                        items: items
                      },
                      navigation: JSON.stringify(navigation),
                      loadingText: '',
                    }, function() {AsyncStorage.setItem('navigation', T.state.navigation)});
                  })
                  .catch((error) => {
                    console.error(error);
                  })
                ;
              }
              break;
          }
        }
      });
  }

  gotoNext = (data) => {
    var dataCopy = {};
    Object.assign(dataCopy, data);
    if (dataCopy.entity === 'verset') {
      dataCopy.parentId++;
      dataCopy.number++;
    }
    if (dataCopy.entity === 'hizb') {
      dataCopy.number++;
    }
    this.populateMain(dataCopy);
  }

  gotoPrev = (data) => {
    var dataCopy = {};
    Object.assign(dataCopy, data);
    if (dataCopy.entity === 'verset') {
      dataCopy.parentId--;
      dataCopy.number--;
    }
    if (dataCopy.entity === 'hizb') {
      dataCopy.number--;
    }
    this.populateMain(dataCopy);
  }

  textHLChanged = (textFr, transFr) => {
    this.setState({
      textFr: textFr,
      transFr: transFr,
    }, function() {AsyncStorage.multiSet([['textFr', ''+this.state.textFr], ['transFr', ''+this.state.transFr]])});
  }

  narratorHLChanged = (narratorId) => {
    this.setState({
      narratorId: narratorId,
    }, function() {AsyncStorage.setItem('narratorId', ''+this.state.narratorId)});
  }

  dataLoaded = async (sourates, narrators, callback) => {
    this.sourates = JSON.parse(JSON.stringify(sourates));
    // remove first 2 entries (label and back)
    this.sourates.shift();
    this.sourates.shift();
    this.narrators = JSON.parse(JSON.stringify(narrators));
    // remove first 2 entries (label and back)
    this.narrators.shift();
    this.narrators.shift();
    const bookmarksItem = await AsyncStorage.getItem('bookmarks');
    var bookmarks = JSON.parse(bookmarksItem);
    this.setState({
      bookmarks: bookmarks ?? {},
    }, function() {
      if (callback) {
        callback(bookmarks);
      }
    });
  }

  changeTextSize = async (lang, plus) => {
    var textArSize = this.state.textArSize;
    var textFrSize = this.state.textFrSize;
    if (lang === 'ar') {
      var index = versetContentArFontSizes.indexOf(textArSize);
      if (plus) {
        if (index >= versetContentArFontSizes.length - 1) {
          return;
        }
        textArSize = versetContentArFontSizes[++index];
      } else {
        if (index <= 0) {
          return;
        }
        textArSize = versetContentArFontSizes[--index];
      }
    }
    if (lang === 'fr') {
      var index = versetContentFrFontSizes.indexOf(textFrSize);
      if (plus) {
        if (index >= versetContentFrFontSizes.length - 1) {
          return;
        }
        textFrSize = versetContentFrFontSizes[++index];
      } else {
        if (index <= 0) {
          return;
        }
        textFrSize = versetContentFrFontSizes[--index];
      }
    }
    this.setState({
      textArSize: textArSize,
      textFrSize: textFrSize,
    }, function() {AsyncStorage.multiSet([['textArSize', ''+this.state.textArSize], ['textFrSize', ''+this.state.textFrSize]])});
  }

  onBack = async () => {
    if (isNative) {
      this.populateMain(this.state.savedDataBeforeSettings);
    }
  }

  onLogoPress = async (dontPushState) => {
    if (isNative === false) {
      var location = window.location;
      var components = location.pathname.split('/');
    }
    if (isNative === false && dontPushState !== true && components[1] !== '') {
      history.pushState({}, '', '/');
    }
    const navigationItem = await AsyncStorage.getItem('navigation');
    var navigation = JSON.parse(navigationItem);
    if (navigation === null) {
      navigation = {};
    }
    navigation.data = {'entity': 'home'};
    this.setState({
      mainContentData: {
        screen: 'home'
      },
      navigation: JSON.stringify(navigation),
      loadingText: '',
      currentSourate: -1,
      currentHizb: -1,
    }, function() {AsyncStorage.setItem('navigation', this.state.navigation)});
  }

  bookmarksEdit = (id, callback) => {
    // remove or add ?
    var bookmarks = this.state.bookmarks;
    if (bookmarks[''+id] !== undefined) {
      delete bookmarks[''+id];
    } else {
      bookmarks[''+id] = 1;
    }
    this.setState({
      bookmarks: bookmarks,
    }, function() {
      AsyncStorage.setItem('bookmarks', JSON.stringify(this.state.bookmarks));
      if (callback) {
        callback(this.state.bookmarks);
      }
    });
  }

  setDarkMode(darkMode) {
    this.setState({
      darkMode: darkMode,
      theme: darkMode ? 'dark' : 'light',
    }, function() {
      AsyncStorage.multiSet([
        ['darkMode', ''+this.state.darkMode]
      ]);
    });
  }

  render() {
    if (this.state.portrait === false) {
      return (
        <SafeAreaView style={[this.styles.scrollView, this.state.theme === 'light' ? {backgroundColor: '#fdf5df'} : {backgroundColor: '#191e2b'} ]} scalesPageToFit>
          <StatusBar barStyle={this.state.theme === 'light' ? 'dark-content' : 'light-content'} backgroundColor={this.state.theme === 'light' ? '#fdf5df' : '#191e2b'} />
          <View style={this.styles.mainContainer}>
            <View style={this.styles.main}>
              <View style={this.styles.loaderContainer}><ActivityIndicator style={[this.styles.loader, this.state.theme === 'light' ? {backgroundColor: '#ffe7ab'} : {backgroundColor: '#3a4151'}]} color={loaderColor}></ActivityIndicator></View>
              <View style={this.styles.loadingTextContainer}><Text selectable={false} style={this.state.theme === 'light' ? {color: '#000'} : {color: '#fff'}}>Veuillez passer en mode portrait s'il vous plaît</Text></View>
            </View>
          </View>
        </SafeAreaView>
      );
    }
    return (
      <SafeAreaView style={[this.styles.scrollView, this.state.theme === 'light' ? {backgroundColor: '#fdf5df'} : {backgroundColor: '#191e2b'} ]} scalesPageToFit>
        <StatusBar barStyle={this.state.theme === 'light' ? 'dark-content' : 'light-content'} backgroundColor={this.state.theme === 'light' ? '#fdf5df' : '#191e2b'} />
        <View style={this.styles.mainContainer}>
          <Animated.View style={[this.styles.main, {transform: [{translateX: this.context.mainXPos}]}]}>
            { this.state.mainContentData.screen === 'loading' ?
              <View style={this.styles.loaderContainer}><ActivityIndicator style={[this.styles.loader, this.state.theme === 'light' ? {backgroundColor: '#ffe7ab'} : {backgroundColor: '#3a4151'}]} color={loaderColor}></ActivityIndicator></View>
              :
              ( this.state.mainContentData.screen === 'home' ?
                <View style={[this.styles.salamContainer, this.state.theme === 'light' ? {backgroundColor: '#fdf5df'} : {backgroundColor: '#222534'}]}>
                  <View style={[this.styles.screenTitleContainer, this.state.theme === 'light' ? this.styles.shadowLight : this.styles.shadowDark, this.state.theme === 'light' ? {backgroundColor: '#fdf5df'} : {backgroundColor: '#191e2b'}]}>
                      <Text selectable={false} style={[this.styles.screenTitle, (this.state.fontsLoaded ? this.styles.contentFr : null), this.state.theme === 'light' ? {color: '#000'} : {color: '#fff'}]}></Text>
                  </View>
                  <View style={[this.styles.salam, this.state.theme === 'light' ? this.styles.shadowLight : this.styles.shadowDark, this.state.theme === 'light' ? {backgroundColor: '#fef8e9'} : {backgroundColor: '#282d38'} ]}>
                    <ImageBackground
                      source={versetCornerUL}
                      style={this.styles.versetCornerUL}
                    />
                    <ImageBackground
                      source={versetCornerLL}
                      style={this.styles.versetCornerLL}
                    />
                    <ImageBackground
                      source={versetCornerUR}
                      style={this.styles.versetCornerUR}
                    />
                    <ImageBackground
                      source={versetCornerLR}
                      style={this.styles.versetCornerLR}
                    />
                    <Text selectable={false} style={[this.styles.salamAr, (this.state.fontsLoaded ? this.styles.contentAr : null), this.state.theme === 'light' ? {color: '#000'} : {color: '#fff'}]}>
                      السٌَلامُ عَلَيْكُمْ وَ رَحْمَةُ اللهِ وَ بَرَكاتُهْ
                    </Text>
                    <Text selectable={false} style={[this.styles.salamFr, (this.state.fontsLoaded ? this.styles.contentFr : null), this.state.theme === 'light' ? {color: '#000'} : {color: '#fff'}]}>
                      As-salam alaykoum wa rahmatu-Llah wa barakatuh
                    </Text>
                  </View>
                  <View>
                    <View style={{flexDirection: 'row'}}>
                      <Text selectable={false} style={[this.styles.presentation, (this.state.fontsLoaded ? this.styles.contentFr : null), this.state.theme === 'light' ? {color: '#000'} : {color: '#fff'}]}>
                        Pour toute remarque, idée d'amélioration ou faute que vous
                        remarquez, veuillez envoyer un mail
                        <Text
                          selectable={false}
                          style={[(this.state.fontsLoaded ? this.styles.linkFF : null), {color: '#0ae'} ]}
                          onPress={() =>
                            Linking.openURL('mailto:contact@qaraa.fr')
                          }>
                          {' '}
                          ici
                        </Text>
                      </Text>
                    </View>
                    <Text selectable={false} style={[this.styles.greetingsAr, (this.state.fontsLoaded ? this.styles.contentAr : null), this.state.theme === 'light' ? {color: '#000'} : {color: '#fff'}]}>
                      جزاكم الله خيرا و بارك الله فِيكُمْ
                    </Text>
                    <Text selectable={false} style={[this.styles.greetingsFr, (this.state.fontsLoaded ? this.styles.contentFr : null), this.state.theme === 'light' ? {color: '#000'} : {color: '#fff'}]}>
                      Jazakoum Allahu khayran wa barakAllahu fikoum
                    </Text>
                    <Text selectable={false} style={[this.styles.greetingsFr, (this.state.fontsLoaded ? this.styles.contentFr : null), this.state.theme === 'light' ? {color: '#000'} : {color: '#fff'}, {fontSize: 9}]}>
                      version {this.state.version}
                    </Text>
                  </View>
                </View>
                :
                ( this.state.mainContentData.screen === 'help' ?
                  <View style={[this.styles.helpContainer, this.state.theme === 'light' ? {backgroundColor: '#fdf5df'} : {backgroundColor: '#222534'} ]}>
                    <View style={[this.styles.screenTitleContainer, this.state.theme === 'light' ? this.styles.shadowLight : this.styles.shadowDark, this.state.theme === 'light' ? {backgroundColor: '#fdf5df'} : {backgroundColor: '#191e2b'}]}>
                        <Text selectable={false} style={[this.styles.screenTitle, (this.state.fontsLoaded ? this.styles.contentFr : null), this.state.theme === 'light' ? {color: '#000'} : {color: '#fff'}]}>Aide</Text>
                    </View>
                    <View style={{height: 30,}} />
                    {hints.map((hint, index) => (
                      <View key={index} style={this.styles.helpLine}>
                        <Text selectable={false} style={[this.styles.helpLine, (this.state.fontsLoaded ? this.styles.contentFr : null), this.state.theme === 'light' ? {color: '#000'} : {color: '#fff'}]}>{hint}</Text>
                        <View style={{height: 40,}} />
                      </View>
                    ))}
                  </View>
                  :
                  <View style={this.styles.sourateViewContainer}>
                    <Sourate
                      styles={this.styles}
                      fontsLoaded={this.state.fontsLoaded}
                      data={this.state.mainContentData.data}
                      items={this.state.mainContentData.items}
                      textFr={this.state.textFr}
                      transFr={this.state.transFr}
                      textArSize={this.state.textArSize}
                      textFrSize={this.state.textFrSize}
                      narrator={this.narrators[this.state.narratorId - 1]}
                      gotoNext={this.gotoNext}
                      gotoPrev={this.gotoPrev}
                      bookmarks={this.state.bookmarks}
                      bookmarksEdit={this.bookmarksEdit}
                      theme={this.state.theme}
                    />
                  </View>
                )
              )
            }
            {this.state.loadingText !== '' ?
              <View style={this.styles.loadingTextContainer}>
                <Text selectable={false} style={this.state.theme === 'light' ? {color: '#000'} : {color: '#fff'}}>{this.state.loadingText}</Text>
              </View>
              :
              null
            }
            <View style={this.styles.logoContainer}>
              <TouchableOpacity style={this.styles.logo} onPress={() => {this.onLogoPress();}}>
                <Image style={[this.styles.logo, {left: 0, top: 0,}]} source={this.state.theme === 'light' ? logo : logoLight} />
              </TouchableOpacity>
            </View>
            <View style={this.styles.settingsContainer}>
              <View style={[this.styles.settingsLine, (this.state.theme === 'light' ? this.styles.shadowLight : this.styles.shadowDark), (this.state.theme === 'light' ? {backgroundColor: '#fef8e9'} : {backgroundColor: '#282d38'}), (this.state.mainContentData.screen === 'sourate' ? {width: 220} : {width: 100})]}>
                <TouchableOpacity onPress={() => {this.changeTextSize('fr', true);}}>
                  { this.state.mainContentData.screen === 'sourate' ?
                    <Text selectable={false} style={[this.styles.settingsLetterUpText, (this.state.fontsLoaded ? this.styles.contentFr : null), this.state.theme === 'light' ? {color: '#000'} : {color: '#fff'}]}>A</Text>
                    :
                    null
                  }
                </TouchableOpacity>
                <TouchableOpacity onPress={() => {this.changeTextSize('fr', false);}}>
                  { this.state.mainContentData.screen === 'sourate' ?
                    <Text selectable={false} style={[this.styles.settingsLetterDownText, (this.state.fontsLoaded ? this.styles.contentFr : null), this.state.theme === 'light' ? {color: '#000'} : {color: '#fff'}]}>A</Text>
                    :
                    null
                  }
                </TouchableOpacity>
                <Text selectable={false} style={[this.styles.settingsSwitchText]}>
                  <IconSolid.MoonIcon size={15} color={this.state.theme === 'light' ? '#000' : '#fff'} />
                </Text>
                <Switch
                  barHeight={20}
                  switchWidth={16}
                  switchHeight={16}
                  value={!this.state.darkMode}
                  onValueChange={value => this.setDarkMode(!value)}
                  backgroundActive={'#3a4151'}
                  backgroundInactive={'#fdf5df'}
                  circleActiveColor={'#fdf5df'}
                  circleInActiveColor={'#3a4151'}
                  changeValueImmediately={true}
                  renderActiveText={false}
                  renderInActiveText={false}
                  switchLeftPx={3}
                  switchRightPx={3}
                />
                <Text selectable={false} style={[this.styles.settingsSwitchText]}>
                  <IconSolid.SunIcon size={15} color={this.state.theme === 'light' ? '#000' : '#fff'} />
                </Text>
                <TouchableOpacity onPress={() => {this.changeTextSize('ar', false);}}>
                  { this.state.mainContentData.screen === 'sourate' ?
                    <Text selectable={false} style={[this.styles.settingsLetterDownTextAr, (this.state.fontsLoaded ? this.styles.contentAr : null), this.state.theme === 'light' ? {color: '#000'} : {color: '#fff'}]}>أ</Text>
                    :
                    null
                  }
                </TouchableOpacity>
                <TouchableOpacity onPress={() => {this.changeTextSize('ar', true);}}>
                  { this.state.mainContentData.screen === 'sourate' ?
                    <Text selectable={false} style={[this.styles.settingsLetterUpTextAr, (this.state.fontsLoaded ? this.styles.contentAr : null), this.state.theme === 'light' ? {color: '#000'} : {color: '#fff'}]}>أ</Text>
                    :
                    null
                  }
                </TouchableOpacity>
              </View>
            </View>
          </Animated.View>
        </View>
        <Menu
          styles={this.styles}
          fontsLoaded={this.state.fontsLoaded}
          menuLoaderColor={menuLoaderColor}
          toggleMenu={this.toggleMenu}
          populateMain={this.populateMain}
          dataLoaded={this.dataLoaded}
          textFr={this.state.textFr}
          transFr={this.state.transFr}
          narratorId={this.state.narratorId}
          bookmarks={this.state.bookmarks}
          textHLChanged={this.textHLChanged}
          narratorHLChanged={this.narratorHLChanged}
          currentSourate={this.state.currentSourate}
          currentHizb={this.state.currentHizb}
        />
        <Animated.View style={[this.styles.menuButtonContainer, {transform: [{translateX: this.context.mainXPos}]}]}>
          <TouchableOpacity style={this.styles.menuButton} onPress={() => {this.toggleMenu();}}>
            <Icon.Bars3Icon size={30} color={this.state.theme === 'light' ? '#642919' : '#fff'} />
          </TouchableOpacity>
        </Animated.View>
        <Animated.View
          onStartShouldSetResponder={() => true}
          onResponderGrant={() => this.toggleMenu()}
          style={[
            this.styles.overScreen,
            {
              transform: [
                {translateX: this.context.overScreenXPos},
                {translateY: this.context.overScreenYPos},
              ],
              opacity: this.context.overScreenOpacity,
            },
          ]}
        />
      </SafeAreaView>
    );
  }
}
