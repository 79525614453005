import React, { Component } from 'react';

import {
  View,
  Text,
  StyleSheet,
  Image,
  TouchableOpacity,
} from 'react-native';
import * as Icon from "react-native-heroicons/outline";
import * as IconS from "react-native-heroicons/solid";


const Controls = ({
  paused,
  loopMode,
  speed,
  onPressPlay,
  onPressPause,
  onPressLoop,
  onPressSpeed,
  disabled,
  styles,
  fontsLoaded,
}) => (
    <View style={styles.playerControlsContainer}>
      {loopMode === 'none' ?
        <TouchableOpacity style={styles.playerControls} disabled={disabled} onPress={onPressLoop}>
          <Icon.ArrowPathRoundedSquareIcon size={40} color='#ffffff33'/>
        </TouchableOpacity>
        :
        <TouchableOpacity style={styles.playerControls} disabled={disabled} onPress={onPressLoop}>
          <Icon.ArrowPathRoundedSquareIcon size={40} color={disabled ? '#ffffff33':'#ffffff'}/>
          {loopMode === 'one' ?
            <Text selectable={false} style={[styles.playerLoopCount, (fontsLoaded ? styles.playerLoopCountFF : null)]}>1</Text>
            :
            null
          }
        </TouchableOpacity>
      }
      {!paused ?
        <TouchableOpacity style={styles.playerControls} disabled={disabled} onPress={onPressPause}>
          <IconS.PauseIcon size={40} color={disabled ? '#ffffff33':'#ffffff'}/>
        </TouchableOpacity>
        :
        <TouchableOpacity style={styles.playerControls} disabled={disabled} onPress={onPressPlay}>
          <IconS.PlayIcon size={40} color={disabled ? '#ffffff33':'#ffffff'}/>
        </TouchableOpacity>
      }
      <TouchableOpacity style={[styles.playerControls]} disabled={disabled} onPress={onPressSpeed}>
        <Text selectable={false} style={[styles.playerSpeedControl, (fontsLoaded ? styles.playerSpeedControlFF : null), {color: (disabled ? '#ffffff33':'#ffffff')}]}>{speed} x</Text>
      </TouchableOpacity>
    </View>
  );

export default Controls;