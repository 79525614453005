import React, {Component} from 'react';
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  Text,
  View,
  Image,
  ImageBackground,
  Platform,
  Animated,
  Easing,
  Dimensions,
  useColorScheme,
  Linking,
  Button,
  TouchableOpacity,
  Pressable,
} from 'react-native';
import * as Icon from "react-native-heroicons/outline";
import * as FileSystem from 'expo-file-system';
import * as Device from 'expo-device';
import AwesomeAlert from 'react-native-awesome-alerts';

import Player from './player/Player';
import Verset from './Verset';

const isNative = Platform.OS !== 'web';
const screenHeight = Dimensions.get('window').height;

export default class Sourate extends Component {
  state = {
    sourates: null,
    currentSourate: null,
    timeCodes: null,
    currentVerset: -1,
    autoVersetChange: false,
    bookmarks: {},
    showControls: false,
    share: false,
    indexes: [],
    flattenVersets: [],
    onscreen: [],
  }
  
  scrollViewRef = null;
  label = '';
  max = 0;
  number = 0;
  versetYPositions = [];
  sourateTitleHeight = 0;

  constructor(props) {
    super(props);
    this.load();
    this.scrollViewRef = React.createRef();
  }

  load = async () => {
    var T = this;
    var summaryJson = [];
    this.versetRefs = [];
    if (isNative) {
      var mediaDir = FileSystem.documentDirectory + 'Qaraa/media/';
      var summaryFile = mediaDir + 'summary.json';
      summaryJson = JSON.parse(await FileSystem.readAsStringAsync(summaryFile));
    } else {
      await fetch('https://qaraa.fr/api/summary')
        .then((response) => response.json())
        .then((json) => {
          summaryJson = json;
        })
        .catch((error) => {
          console.error(error);
        })
      ;
    }
    var count = 0;

    var items = Object.values(this.props.items);
    var indexes = [];
    for (var i in items) {
      indexes.push(count);
      count += (items[i].versets.length);
    };

    // flatten items for versets
    var flattenVersets = [];
    for (var i in items) {
      flattenVersets = flattenVersets.concat(items[i].versets);
    };
    // add last for next/prev buttons
    flattenVersets.push({'nextPrev': true});

    this.setState({
      flattenVersets: flattenVersets,
      indexes: indexes,
    }, function() {
      T.label = T.props.data.entity === 'verset' ? 'Sourate' : 'Hizb';
      T.max = T.props.data.entity === 'verset' ? 114 : 60;
      T.number = parseInt(T.props.data.number);

      var audioUrl = '';
      var narratorId = T.props.narrator['entity_id'];

      var timeCodes = [];
      var sourates = [];
      for (var i in items) {
        var verset = items[i].versets[0];
        var sourateNum = verset.sourateNumber;
        var formattedSourateNum = String(sourateNum).padStart(3, '0');
        if (false) { //isNative) {
          var mediaDir = FileSystem.documentDirectory + 'Qaraa/media/';
          audioUrl = mediaDir+narratorId+'_'+formattedSourateNum+'.mp3';
        } else {
          audioUrl = 'https://qaraa.fr/media/'+narratorId+'_'+formattedSourateNum+'.mp3';
        }
        sourates[sourateNum] = {
          title: ('Sourate '+sourateNum)+(T.props.data.entity === 'verset' ?' - '+verset.sourateNames['2']:' (Hizb '+T.number+')'),
          artist: T.props.narrator.data.narratorNames['2'],
          audioUrl: audioUrl,
          totalLength: Math.floor(summaryJson[narratorId][sourateNum]['duration']),
          number: T.number,
        };
        if (items[i].timeCodes) {
          timeCodes[sourateNum] = items[i].timeCodes;
        }
      }

      T.setState({
        sourates: sourates,
        currentSourate: items[0].versets[0].sourateNumber,
        timeCodes: timeCodes,
        narrator: T.props.narrator,
      }, function() {
        setTimeout(function () {
          // goto sourate / verset ?
          if (T.props.data['goto']) {
            // be sure this goto still exists in bookmarks
            var sourate = T.props.data['goto']['sourate'];
            var verset = T.props.data['goto']['verset'];
            if (T.props.bookmarks[T.label+'-'+T.number+'-'+sourate+'-'+verset] !== undefined) {
              T.selectOneVerset(sourate, verset, false);
            }
          } else {
            T.handleScroll();
          }
        }, 1000);
      });
    });
  }

  selectOneVerset = (sourate, verset, auto, longPress) => {
    this.setState({
      currentSourate: sourate,
      currentVerset: verset,
      autoVersetChange: auto,
      showControls: longPress === true ? true : false,
    });
    var y = 0;
    if (longPress !== true) {
      // different sourate ?
      if (this.state.flattenVersets[0].sourateNumber !== sourate) {
        // changing sourate, we must compute
        var diff = sourate - this.state.flattenVersets[0].sourateNumber;
        verset += this.state.indexes[diff] + 1;
      } else {
        // first verset on list, to compute the offset
        verset -= this.state.flattenVersets[1].number - 1;
      }
      var goto = - this.props.styles.settingsLine.height - 30;
      for (var i in this.versetYPositions) {
        if (parseInt(i) === parseInt(verset)) {
          break;
        }
        goto += this.versetYPositions[i];
      }
      this.scrollViewRef.current?.scrollTo({y: goto, animated: true});
    }
  }

  bookmark = (sourate, verset) => {
    var T = this;
    this.props.bookmarksEdit(this.label+'-'+this.number+'-'+sourate+'-'+verset, function(bookmarks) {
      T.setState({
        bookmarks: bookmarks,
      });
    });
  }

  share = (sourate, verset) => {
    this.setState({
      currentSourate: sourate,
      currentVerset: verset,
      share: true,
    });
  }

  shareDone = () => {
    this.setState({
      share: false,
    });
  }

  handleScroll = (event) => {
    // which verset is on scope ?
    var yMin = 0;
    if (event) {
      yMin = event.nativeEvent.contentOffset.y - screenHeight;
    }
    var yMax = yMin + screenHeight*3;
    var onscreen = [];
    var y = 0;
    for (var i in this.versetYPositions) {
      y += this.versetYPositions[i];
      if (y > yMin && y < yMax) {
        onscreen[parseInt(i)] = 1;
      }
    }
    if (onscreen !== this.state.onscreen) {
      this.setState({
        onscreen: onscreen,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    var T = this;
    if (props.narrator && props.narrator['entity_id'] !== this.state.narrator['entity_id']) {
      T.load();
      T.setState({
        narrator: props.narrator,
      });
    }

    if (props.theme) {
      // indexes is state based now to allow sticky rerender
      // on theme change (for background color)
      var indexes = T.state.indexes;
      T.setState({
        indexes: [],
      }, function() {
        T.setState({
          indexes: indexes,
        });
      });
    }
  }

  render() {
    var narratorId = this.props.narrator['entity_id'];
    var items = Object.values(this.props.items);
    var flattenVersets = this.state.flattenVersets;
    var currentVerset = this.state.currentVerset;
    var currentSourate = this.state.currentSourate;
    var timeCodes = this.state.timeCodes;
    var bookmarks = this.state.bookmarks;
    var share = this.state.share;
    var showControls = this.state.showControls;
    var sourates = this.state.sourates;
    var autoVersetChange = this.state.autoVersetChange;
    var theme = this.props.theme;
    var styles = this.props.styles;
    var indexes = this.state.indexes;
    return (
      <View style={styles.sourateScrollViewContainer}>
        <Animated.ScrollView
          contentInsetAdjustmentBehavior="automatic"
          contentContainerStyle={theme === 'light' ? {backgroundColor: '#fdf5df'} : {backgroundColor: '#222534'}}
          stickyHeaderIndices={indexes}
          ref={this.scrollViewRef}
          onScroll={this.handleScroll}
        >
          {flattenVersets.map((item, index) => (
            (item.nextPrev === undefined ?
              <View key={index}>
                {item.sourateNames !== undefined ?
                  <View
                    onLayout={({nativeEvent}) => {
                      this.versetYPositions[index] = Math.floor(nativeEvent.layout.height);
                    }}
                    style={[styles.screenTitleContainer, (((item.sourateNames !== undefined && !isNative) || isNative) ? (theme === 'light' ? styles.shadowLight : styles.shadowDark) : null), theme === 'light' ? {backgroundColor: '#fdf5df'} : {backgroundColor: '#191e2b'}]}
                  >
                    <Text selectable={false} numberOfLines={1} style={[styles.sourateTitle, (this.props.fontsLoaded ? styles.contentAr : null), theme === 'light' ? {color: '#000'} : {color: '#fff'}]}>{item.sourateNames['1']}</Text>
                  </View>
                  :
                  <View
                    onLayout={({nativeEvent}) => {
                      this.versetYPositions[index] = Math.floor(nativeEvent.layout.height);
                    }}
                    style={[styles.sourateContainer, theme === 'light' ? {backgroundColor: '#fdf5df'} : {backgroundColor: '#222534'}]}
                  >
                    <Verset
                      styles={styles}
                      fontsLoaded={this.props.fontsLoaded}
                      item={item}
                      sourate={item.sourateNumber}
                      textFr={this.props.textFr}
                      transFr={this.props.transFr}
                      textArSize={this.props.textArSize}
                      textFrSize={this.props.textFrSize}
                      timeCodes={(timeCodes !== null && timeCodes[item.sourateNumber][narratorId] !== undefined)? timeCodes[item.sourateNumber][narratorId][item.number] : null}
                      selected={currentVerset === item.number && currentSourate === item.sourateNumber}
                      bookmarked={this.props.bookmarks[this.label+'-'+this.number+'-'+item.sourateNumber+'-'+item.number] !== undefined || bookmarks[this.label+'-'+this.number+'-'+item.sourateNumber+'-'+item.number] !== undefined}
                      selectOneVerset={this.selectOneVerset}
                      bookmark={this.bookmark}
                      theme={theme}
                      share={share && currentVerset === item.number && currentSourate === item.sourateNumber}
                      shareDone={this.shareDone}
                      onscreen={this.state.onscreen[index] !== undefined}
                    />
                  </View>
                }
              </View>
              :
              <View key={index}>
                <View style={styles.nextPrevButtonContainer}>
                  {this.number < this.max ?
                    <TouchableOpacity style={[styles.nextPrevButton, theme === 'light' ? styles.shadowLight : styles.shadowDark, theme === 'light' ? {backgroundColor: '#fef8e9'} : {backgroundColor: '#141822'}]} onPress={() => {this.props.gotoNext(this.props.data);}}>
                      <View style={styles.nextPrevSVGRightLeftArrowIconContainer}>
                        <View style={styles.nextPrevSVGRightLeftArrowIcon}><Icon.ChevronLeftIcon size={20} color={theme === 'light' ? '#000' : '#fff'}/></View>
                        <Text selectable={false} style={[styles.nextPrevText, (this.props.fontsLoaded ? styles.contentFr : null), theme === 'light' ? {color: '#000'} : {color: '#fff'}]}>{this.label} {this.number + 1}</Text>
                      </View>
                    </TouchableOpacity>
                  :
                    null}
                  {this.number > 1 ?
                    <TouchableOpacity style={[styles.nextPrevButton, theme === 'light' ? styles.shadowLight : styles.shadowDark, theme === 'light' ? {backgroundColor: '#fef8e9'} : {backgroundColor: '#141822'}]} onPress={() => {this.props.gotoPrev(this.props.data);}}>
                      <View style={styles.nextPrevSVGRightLeftArrowIconContainer}>
                        <Text selectable={false} style={[styles.nextPrevText, (this.props.fontsLoaded ? styles.contentFr : null), theme === 'light' ? {color: '#000'} : {color: '#fff'}]}>{this.label} {this.number - 1}</Text>
                        <View style={styles.nextPrevSVGRightLeftArrowIcon}><Icon.ChevronRightIcon size={20} color={theme === 'light' ? '#000' : '#fff'}/></View>
                      </View>
                    </TouchableOpacity>
                  :
                    null}
                </View>
              </View>
            )
          ))}
        </Animated.ScrollView>
        <Player
          styles={styles}
          fontsLoaded={this.props.fontsLoaded}
          sourates={sourates}
          currentSourate={currentSourate}
          currentVerset={currentVerset}
          autoVersetChange={autoVersetChange}
          currentPosition={(timeCodes !== null && timeCodes[currentSourate][narratorId] !== undefined && currentVerset !== -1) ? 
            (currentSourate == 1 ? timeCodes[currentSourate][narratorId][currentVerset] : timeCodes[currentSourate][narratorId][currentVerset])
            :
            null
          }
          selectOneVerset={this.selectOneVerset}
          timeCodes={(timeCodes !== null && timeCodes[currentSourate][narratorId] !== undefined)? timeCodes[currentSourate][narratorId] : null}
        />
        <AwesomeAlert
          alertContainerStyle={styles.versetControlsAlertContainer}
          contentContainerStyle={[styles.versetControlsAlert, theme === 'light' ? {backgroundColor: '#fdf5df'} : {backgroundColor: '#191e2b'}]}
          show={showControls}
          showProgress={false}
          customView={<View style={[styles.versetControlsContainer, theme === 'light' ? {backgroundColor: '#fdf5df'} : {backgroundColor: '#191e2b'}]}>
            {/* no share in phone browsers */}
            { (isNative === false && !(Device.osName === 'Mac OS' || Device.osName === 'Windows')) ?
              null
              :
              <TouchableOpacity
                style={styles.versetControls}
                onPress={() => {this.share(currentSourate, currentVerset);}}
              >
                  <Icon.ArrowUpTrayIcon size={30} color={theme === 'light' ? '#642919' : '#fff'}/>
                  <Text selectable={false} style={[styles.versetControlsText, {color: (theme === 'light' ? '#642919' : '#fff')}]}>Partager le verset</Text>
              </TouchableOpacity>
            }
            {this.props.bookmarks[this.label+'-'+this.number+'-'+currentSourate+'-'+currentVerset] !== undefined || bookmarks[this.label+'-'+this.number+'-'+currentSourate+'-'+currentVerset] !== undefined ?
              <TouchableOpacity
                style={styles.versetControls}
                onPress={() => {this.bookmark(currentSourate, currentVerset);}}
              >
                <Icon.BookmarkSlashIcon size={30} color={theme === 'light' ? '#642919' : '#fff'}/>
                <Text selectable={false} style={[styles.versetControlsText, {color: (theme === 'light' ? '#642919' : '#fff')}]}>Enlever marque-page</Text>
              </TouchableOpacity>
            :
              <TouchableOpacity
                style={styles.versetControls}
                onPress={() => {this.bookmark(currentSourate, currentVerset);}}
              >
                <Icon.BookmarkIcon size={30} color={theme === 'light' ? '#642919' : '#fff'}/>
                <Text selectable={false} style={[styles.versetControlsText, {color: (theme === 'light' ? '#642919' : '#fff')}]}>Ajouter marque-page</Text>
              </TouchableOpacity>
            }
          </View>}
          useNativeDriver={true}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={false}
          onDismiss={() => {var T = this; setTimeout(function() {T.setState({showControls: false});}, 200);}}
        />
      </View>
    );
  }
}
